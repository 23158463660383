<script>
  import { onMount } from "svelte";
  import { selectedArea } from "../../stores/stores.js";
  import { createAxisInterval } from "../../utils/createAxisInterval.js";
  import { alignTextToRight } from "../../utils/alignTextToRight.js";
  import * as d3 from "d3";
  export let data;

  let windowWidth;

  const dataset = data.map((value) => {
    return { values: value.values };
  });

  const getValuePairs = () => {
    let valuePair = [];
    const [firstList, secondList] = dataset;
    secondList.values.forEach((elemenent, index) => {
      valuePair.push({
        name: elemenent.name,
        secondVal: elemenent.value,
        firstVal: firstList.values.find((item) => item.name === elemenent.name)
          .value,
      });
    });
    return valuePair;
  };

  const handleOnClick = (d) => {
    selectedArea.set(d.name);
  };

  const handleMouseOver = (e) => {
    d3.select(e.target).transition().duration(300).style("r", "10");
  };
  const handleMouseOut = (e) => {
    d3.select(e.target).transition().duration(300).style("r", "6");
  };

  const colorSelectedValues = (svg) => {
    svg.selectAll(".oldvalue").style("fill", (d) => {
      return d.name === $selectedArea ? "#ffaf99" : "#cccccc";
    });
    svg.selectAll(".secondValue").style("fill", (d) => {
      return d.name === $selectedArea ? "#ff3900" : "#00818f";
    });
    svg
      .selectAll(".horizonLines")
      .attr("stroke", (d) => {
        return d.name === $selectedArea ? "#ffaf99" : "#cccccc";
      })
      .attr("stroke-width", (d) => {
        return d.name === $selectedArea ? "2px" : "2px";
      });

    svg.selectAll(".arealabel").style("font-weight", (d) => {
      return d.name === $selectedArea ? "bold" : "normal";
    });
    svg.selectAll(".value").style("font-weight", (d) => {
      return d.name === $selectedArea ? "bold" : "normal";
    });
  };

  let svgRef;
  let cheight, cwidth;
  onMount(() => {
    // set the dimensions and margins of the graph
    const margin = {
        top: 40,
        right: 100,
        bottom: 30,
        left: windowWidth > 1440 ? 200 : 170,
      },
      width = cwidth - margin.left - margin.right,
      height = cheight - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select(svgRef)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`);
    // Parse the Data

    // Add X axis
    const x = d3
      .scaleLinear()
      .domain([0, d3.max(getValuePairs().map((value) => value.secondVal))])
      .range([0, width]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(d3.axisBottom(x));
    svg.select(".domain").attr("stroke", "transparant");

    // Y axis
    const y = d3
      .scaleBand()
      .range([0, height])
      .domain(
        getValuePairs().map(function (d) {
          return d.name;
        })
      )
      .padding(1);

    //Labels and values
    svg
      .selectAll(".dotpairs")
      .data(getValuePairs())
      .join("text")
      .attr("class", "arealabel")
      .attr("x", -5)
      .attr("y", (d) => y(d.name) + 5)
      .style("font-family", "Lato")
      .style("font-size", windowWidth > 1440 ? "medium" : "small")
      .style("font-weight", "400")
      .style("color", "#333")
      .style(
        "transform",
        windowWidth > 1440 ? "translate(-200px, 0px)" : "translate(-160px, 0px)"
      )
      .style("cursor", "pointer")
      .on("click", (e, d) => handleOnClick(d))
      .text((d) => d.name);

    svg.selectAll(".arealabel").attr("x", function () {
      return alignTextToRight(svg, ".arealabel", this);
    });

    svg
      .selectAll(".dotpairs")
      .data(getValuePairs())
      .join("text")
      .attr("x", width)
      .attr("class", "value")
      .attr("y", (d) => y(d.name) + 5)
      .style(
        "transform",
        windowWidth > 1440 ? "translate(15px, 0px)" : "translate(10px, 0px)"
      )
      .style("font-size", windowWidth > 1440 ? "medium" : "small")
      .style("cursor", "pointer")
      .on("click", (e, d) => handleOnClick(d))
      .text((d) => {
        const sum = d.secondVal - d.firstVal;
        return sum >= 0
          ? `${d.secondVal} (+${sum})`
          : `${d.secondVal} (${sum})`;
      });

    //Legend

    const legend = svg.append("g").attr("class", "legend");
    legend
      .attr("x", 0)
      .attr("y", height)
      .style("transform", "translate(0px, -30px)");
    legend
      .append("circle")
      .attr("cx", function () {
        return width - this.getBBox().width - 150;
      })
      .attr("cy", 0)
      .attr("r", 6)
      .style("fill", "#cccccc");
    legend
      .append("circle")
      .attr("cx", function () {
        return width - this.getBBox().width - 50;
      })
      .attr("cy", 0)
      .attr("r", 6)
      .style("fill", "#00818f");
    legend
      .append("text")
      .text(data[0].objectInfo.kolomnaam)
      .attr("x", function (d) {
        return width - this.getBBox().width - 100;
      })
      .attr("y", 0)
      .style("font-size", "15px")
      .attr("alignment-baseline", "central")
      .style("dominant-baseline", "middle")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");
    legend
      .append("text")
      .text(data[1].objectInfo.kolomnaam)
      .attr("x", function () {
        return width - this.getBBox().width;
      })
      .attr("y", 0)
      .style("font-size", "15px")
      .attr("alignment-baseline", "central")
      .style("dominant-baseline", "middle")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");

    //Raster
    svg
      .selectAll("myline")
      .data(
        createAxisInterval(
          100,
          dataset[0].values.map((entry) => entry.value)
        )
      )
      .join("line")
      .attr("x1", (d) => x(d))
      .attr("x2", (d) => x(d))
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke", "#bababa")
      .attr("stroke-width", "1px");

    svg
      .selectAll("myline")
      .data(getValuePairs())
      .join("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => y(d.name))
      .attr("y2", (d) => y(d.name))
      .attr("stroke", "#bababa")
      .attr("stroke-width", "1px")
      .attr("stroke-dasharray", "10,5,10");

    //Horizantal Lines
    svg
      .selectAll(".dotpairs")
      .data(getValuePairs())
      .join("line")
      .attr("x1", function (d) {
        return x((d.firstVal + d.secondVal) / 2);
      })
      .attr("x2", function (d) {
        return x((d.firstVal + d.secondVal) / 2);
      })
      .attr("y1", function (d) {
        return y(d.name);
      })
      .attr("y2", function (d) {
        return y(d.name);
      })
      .attr("stroke", "#cccccc")
      .attr("stroke-width", "2px")
      .attr("class", "horizonLines");

    svg
      .selectAll(".horizonLines")
      .data(getValuePairs())
      .transition()
      .duration(1000)
      .attr("x1", (d) => x(d.firstVal))
      .attr("x2", (d) => x(d.secondVal));

    // Circles of variable 1
    svg
      .selectAll(".dotpairs")
      .data(getValuePairs())
      .join("circle")
      .on("click", (e, d) => handleOnClick(d))
      .on("mouseover", (e) => handleMouseOver(e))
      .on("mouseout", (e) => handleMouseOut(e))
      .attr("cx", function (d) {
        return x((d.firstVal + d.secondVal) / 2);
      })
      .attr("cy", function (d) {
        return y(d.name);
      })
      .attr("r", "6")
      .style("fill", "#cccccc")
      .style("cursor", "pointer")
      .attr("class", "oldvalue");

    svg
      .selectAll(".oldvalue")
      .data(getValuePairs())
      .transition()
      .duration(1000)
      .attr("cx", function (d) {
        return x(d.firstVal);
      });

    // Circles of variable 2
    svg
      .selectAll(".dotpairs")
      .data(getValuePairs())
      .join("circle")
      .on("click", (e, d) => handleOnClick(d))
      .on("mouseover", (e) => handleMouseOver(e))
      .on("mouseout", (e) => handleMouseOut(e))
      .attr("cx", function (d) {
        return x((d.firstVal + d.secondVal) / 2);
      })
      .attr("cy", function (d) {
        return y(d.name);
      })
      .attr("r", "6")
      .style("fill", "#00818f")
      .style("cursor", "pointer")
      .attr("class", "secondValue");

    svg
      .selectAll(".secondValue")
      .data(getValuePairs())
      .transition()
      .duration(1000)
      .attr("cx", function (d) {
        return x(d.secondVal);
      });
    selectedArea.subscribe(() => colorSelectedValues(svg));
  });
</script>

<svelte:window bind:innerWidth={windowWidth} />

<div bind:clientHeight={cheight} bind:clientWidth={cwidth} class="container">
  <div bind:this={svgRef} />
</div>

<style>
  .container {
    min-height: 85%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
