<div class="container">
  <div class="dot" />
  <div class="dot" />
  <div class="dot" />
  <div class="dot" />
  <div class="dot" />
</div>

<style>
  .container {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
  }
  .dot {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: lightgray;
    display: inline-block;
    margin: 0.5rem;
    animation: scaling 2.5s ease-in-out infinite;
  }

  .dot:nth-child(0) {
    animation-delay: 0.2s;
  }
  .dot:nth-child(1) {
    animation-delay: 0.4s;
  }
  .dot:nth-child(2) {
    animation-delay: 0.6s;
  }
  .dot:nth-child(3) {
    animation-delay: 0.8s;
  }
  .dot:nth-child(4) {
    animation-delay: 1s;
  }

  @keyframes scaling {
    0%,
    100% {
      transform: scale(0.2);
    }
    40% {
      transform: scale(1);
    }
    50% {
      transform: scale(1);
    }
  }
</style>
