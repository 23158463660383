<script>
  import { tweened } from "svelte/motion";
  import { cubicOut, elasticInOut } from "svelte/easing";
  import { fade } from "svelte/transition";
  import { createEventDispatcher } from "svelte";

  export let options;
  let placeholder = options[0];
  let dropdownlist = options.filter((item) => item != placeholder);

  let rotation = tweened(0, {
    duration: 500,
    easing: cubicOut,
  });

  let isOpen = false;

  let dispatch = createEventDispatcher();

  const handleClick = (column) => {
    dispatch("change", {
      column,
    });
    toggleBox();
    placeholder = column;
    dropdownlist = options.filter((item) => item != column);
  };

  const toggleBox = () => {
    isOpen = !isOpen;
    isOpen ? rotation.set(180) : rotation.set(0);
  };
</script>

<div class="container">
  <div class="box">
    <div class="listContainer">
      {#if isOpen}
        <div
          class="list"
          in:fade={{ delay: (options.length + 1) * 10 }}
          out:fade={{
            delay: (dropdownlist.length - (options.length + 1)) * 10,
          }}
        >
          {#each dropdownlist as item, index}
            <p
              in:fade={{ delay: (index + 1) * 10 }}
              out:fade={{ delay: (dropdownlist.length - (index + 1)) * 10 }}
              on:click={() => handleClick(item)}
            >
              {item}
            </p>
          {/each}
        </div>{/if}
    </div>
    <div class="field">
      <div class="title"><h1>Data op kaart:</h1></div>
      <div class="dropdownbox" on:click={toggleBox}>
        <div class="placeholder">{placeholder}</div>
        <div class="arrow" style={"transform: rotate(" + $rotation + "deg);"}>
          ▼
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  h1 {
    font-size: large;
    font-family: "Poppins";
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .container {
    width: 100%;
    height: 1rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-end;
  }
  .box {
    display: flex;
    flex-direction: column;
    width: 40%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    gap: 2px;
  }
  .title {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: left;
  }
  .dropdownbox {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: right;
    align-items: center;
    border-bottom: solid;
    color: lightgray;
    cursor: pointer;
  }
  .arrow {
    font-size: small;
    color: gray;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .placeholder {
    margin-right: 5px;
    font-size: large;
    color: gray;
  }

  .listContainer {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .list {
    background-color: white;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    margin-right: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: right;
    align-items: flex-end;
    flex-grow: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 10;
  }

  p {
    flex-shrink: 1;
    margin-bottom: 5px;
    margin-top: 5px;
    cursor: pointer;
  }

  @media (max-width: 1440px) {
    p {
      font-size: small;
      word-break: keep-all;
      text-align: right;
    }
    h1 {
      font-size: small;
    }
    .placeholder {
      font-size: small;
    }
  }

  @media (max-width: 770px) {
    .box {
      width: 75%;
    }
    .list {
      padding-right: 0;
    }
    .list p {
      margin-right: 10px;
    }
  }
</style>
