<div class="container">
  <h1>
    <slot name="title">
      <em>No title found</em>
    </slot>
  </h1>
  <p>
    <slot name="subtitle">
      <em>No subtitle found</em>
    </slot>
  </p>
</div>

<style>
  .container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  h1 {
    font-size: 2rem;
    color: black;
    padding-top: 1rem;
  }

  p {
    color: black;
    font-size: 1rem;
    margin: 0px;
  }

  @media (max-width: 1600px) and (min-width: 1441px) {
    .container {
      max-width: 1600px;
      padding-left: 34px;
    }
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: x-large;
    }

    p {
      font-size: small;
    }
  }

  @media (max-height: 880px) {
    h1 {
      font-size: x-large;
    }
    p {
      font-size: small;
    }
  }

  @media (max-height: 700px) {
    h1 {
      font-size: medium;
    }
    p {
      font-size: small;
      line-height: 1rem;
    }
  }

  @media (max-width: 770px) {
    h1 {
      font-size: medium;
    }
    p {
      font-size: small;
      line-height: 1rem;
    }
  }
</style>
