<script>
  import Topics from "./menus/Topics.svelte";
  import ShareMenu from "./menus/ShareMenu.svelte";
  export let areaInfo;
  export let openOption = null;

  const options = [{ name: "Topics", component: Topics, data: areaInfo }];
  let isOpen = false;
  let isShareMenu = false;
  let maxWith;
  const active = (name) => {
    return name === openOption?.name && isOpen ? "active" : "";
  };

  const setOption = (option) => {
    openOption = option;
  };

  const toggleShare = (value) => {
    isShareMenu = value;
  };

  const toggleIsOpen = () => {
    isOpen = !isOpen;
  };

  const handleShare = () => {
    toggleShare(!isShareMenu);
  };

  const blurShare = () => {
    if (isShareMenu) {
      isShareMenu = false;
    }
  };

  const handleClick = (option) => {
    if (openOption != option) {
      setOption(option);
    }
    toggleIsOpen();
  };
</script>

<svelte:window bind:innerWidth={maxWith} />

<div class="column">
  <div class="navbar">
    <div class="container">
      <div class="left-side">
        <h1 class="title">BXL.Kinderopvang</h1>
        {#if maxWith > 1300}<p class="subtitle">
            Cartografie van de Nederlandstalige Gezinsvoorzieningen in Brussel
          </p>
        {/if}
      </div>

      <div class="right-side">
        <div class="buttonContainer">
          {#key isOpen}
            {#each options as option}
              <button
                type="button"
                class="navlink {active(option.name)}"
                on:click={() => handleClick(option)}>{option.name}</button
              >
            {/each}{/key}
          <button type="button" class="share" on:click={() => handleShare()}>
            <img
              src="./svg/shareButton.svg"
              alt="share"
              height="20px"
              width="20px"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
  {#if isOpen}<svelte:component
      this={openOption.component}
      {toggleIsOpen}
      insertedData={openOption.data}
    />{/if}
  {#if isShareMenu}
    <ShareMenu {blurShare} />
  {/if}
</div>

<style>
  .column {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    flex-direction: column;
  }
  .navbar {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-justify-content: center;
    display: flex;
    justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    background-color: white;
    border-bottom: solid;
    border-color: #00818f;
    border-spacing: 0rem;
    overflow: hidden;
  }

  .container {
    flex-grow: 1;
    padding-left: 5px;
    padding-right: 5px;
    max-width: 1738px;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    -webkit-justify-content: space-between;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;

    -ms-flex-pack: space-between;
    align-items: center;
  }

  .left-side {
    flex-grow: 1;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: left;
    -webkit-justify-content: flex-start;
    -ms-justify-content: flex-start;
    align-items: center;
    padding: 2px;
  }

  .right-side {
    flex-shrink: 1;
    width: 75%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    overflow: hidden;
    padding: 2px;
    padding-bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: right;
    -webkit-justify-content: flex-end;
    -ms-justify-content: flex-end;
    align-items: center;
  }

  .title {
    font-weight: bold;
    font-size: large;
    color: #03353b;
    margin-right: 10px;
  }

  .subtitle {
    color: grey;
    opacity: 0.8;
  }

  .title-link {
    text-decoration: none;
  }

  .navlink {
    width: 100%;
    height: 5%;
    color: white;
    background-color: #00818f;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 5px 20px;

    border: none;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    border-radius: 3px;
    transition: padding-bottom 2s;
    cursor: pointer;
  }
  :focus {
    opacity: 100%;
  }

  .active {
    padding-bottom: 25%;
    margin-bottom: 0px;
    margin-top: 22%;
  }

  .buttonContainer {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
    overflow: hidden;
  }

  .share {
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    padding: 5px 20px;
    cursor: pointer;
    border: none;
    background: transparent;
  }

  button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    align-items: center;
  }

  @media (max-width: 1440px) {
    .container {
      max-width: none;
      width: 100%;
    }
  }
  @media (max-width: 1024px) {
  }

  @media (max-width: 770px) {
    .navlink {
      visibility: hidden;
    }
  }
</style>
