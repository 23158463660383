
export const centerTextToGraph = (svg, identifierBar) => {
  if (svg) {
    const height = svg
      .select(identifierBar)
      .nodes()
      .map((value) => value.getBBox().height);
    const result = height.pop() / 2;
    return result;
  }
};
