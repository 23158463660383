<script>
  export let data = [];
  export let maxheight;

  import {
    selectedArea,
    selectedVar,
    selectedLevel,
  } from "../../stores/stores";
  import { clickOutside } from "../../utils/clickOutside.js";
  import { tweened } from "svelte/motion";
  import { cubicOut, elasticInOut } from "svelte/easing";
  import List from "./partials/List.svelte";
  import { addAdfix } from "../../utils/addAdfix.js";
  import { onMount } from "svelte";

  let isOpen = false;
  let highlightedValue = "";
  let highlightedVar = "";

  let height = tweened(0, {
    duration: 400,
    easing: cubicOut,
  });

  let rotation = tweened(0, {
    duration: 900,
    easing: elasticInOut,
  });

  const handleClick = () => {
    isOpen = !isOpen;
    if (isOpen) {
      const heightValue = (maxheight / 100) * 67;
      height.set(heightValue);
      rotation.set(180);
    } else {
      height.set(0);
      rotation.set(0);
    }
  };

  const handleBlur = () => {
    isOpen = false;
    height.set(0);
    rotation.set(0);
  };

  const searchValue = (list, searchVal, prevList = []) => {
    if (list.length === 0) {
      if (prevList.length > 0) {
        return searchValue(prevList, searchVal);
      } else {
        return null;
      }
    } else {
      const shiftedVal = list.shift();
      if (Array.isArray(shiftedVal)) {
        return searchValue(shiftedVal, searchVal, list);
      }
      if (shiftedVal.id === searchVal) {
        if (shiftedVal.graph === "Dumbbell") {
          const [firstVal, secondVal] = [shiftedVal, ...list];

          const sum = secondVal.value - firstVal.value;
          const value =
            sum >= 0
              ? `${secondVal.value} (+${sum})`
              : `${secondVal.value} (${sum})`;
          const newVal = { ...shiftedVal, value };
          return newVal;
        }

        if (shiftedVal.graph === "Spreidingsdiagram") {
          const values = [shiftedVal, ...list]
            .sort((a, b) => a.columnNumber - b.columnNumber)
            .slice(-2);
          const [xVal, yVal] = values.map((item) => item.value);
          const newVal = { ...shiftedVal, value: `${xVal} / ${yVal}` };
          return newVal;
        }

        if (shiftedVal.id === 235) {
          const values = [shiftedVal, ...list];
          const sum = values.reduce((acc, current) => {
            acc = parseFloat(current.value + acc);
            return acc;
          }, 0);
          return { ...shiftedVal, value: sum };
        }

        return shiftedVal.value
          ? shiftedVal
          : searchValue(list, searchVal, prevList);
      } else {
        return searchValue(list, searchVal, prevList);
      }
    }
  };

  const handleHighlightedValue = (value) => {
    const values = data.map((item) => item.values).flat();
    const result = searchValue(values, value);
    return result;
  };
  const handleChange = (value) => {
    const highlightedObject = handleHighlightedValue(value);
    if (highlightedObject) {
      highlightedVar = highlightedObject.shortName;
      if (
        highlightedObject.graph === "Dumbbell" ||
        highlightedObject.graph === "Spreidingsdiagram"
      ) {
        highlightedValue = highlightedObject.value;
      }
      if (
        highlightedObject.graph === "Gestapeld staafdiagram" &&
        highlightedObject.cat === "Aanvragen"
      ) {
        highlightedValue = " ";
      } else {
        highlightedValue = highlightedObject.adfix
          ? addAdfix(
              highlightedObject.adfix,
              parseFloat(highlightedObject.value).toLocaleString()
            )
          : parseFloat(highlightedObject.value).toLocaleString();
        highlightedVar = highlightedObject.shortName;
      }
    } else {
      highlightedValue = "";
      highlightedVar = "";
    }
  };

  onMount(() => {
    selectedVar.subscribe((value) => {
      handleChange(value);
    });
    selectedArea.subscribe(() => {
      setTimeout(() => {
        handleChange($selectedVar);
      }, 50);
    });
  });
</script>

<div
  class="container"
  use:clickOutside
  on:click_outside={() => (isOpen ? handleBlur() : null)}
>
  <div class="innerContainer">
    <div class="collapseButton" on:click={handleClick}>
      <h1 class="area">
        {$selectedArea || "Selecteer een locatie op de kaart"}
      </h1>
      <div class="icon">
        <img
          class="arrowUp"
          src="./images/arrowUpWhite.png"
          alt="open menu"
          height="40"
          width="40"
          style="transform: rotate({$rotation}deg);"
        />
      </div>
    </div>
    <div class="topField">
      <div class="sidebyside">
        <p class="variable">{highlightedVar}</p>
        {#key data || $selectedLevel}
          <p class="value">
            {highlightedValue || "Nog geen waarde"}
          </p>{/key}
      </div>
    </div>
    <div class="collapsed" style="height: {$height}px;">
      <div class="scroll">
        {#key data}
          {#each data as info}
            <List data={info.values}
              ><div slot="title">{info.category}</div></List
            >{/each}{/key}
      </div>
    </div>
  </div>
</div>

<style>
  .container {
    flex-shrink: 1;
    position: absolute;
    z-index: 100;
    bottom: 0;
    right: 0;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    box-shadow: 1.7px 2.8px 2.2px rgba(0, 0, 0, 0.02),
      4px 6.7px 5.3px rgba(0, 0, 0, 0.028),
      7.5px 12.5px 10px rgba(0, 0, 0, 0.035),
      13.4px 22.3px 17.9px rgba(0, 0, 0, 0.042),
      25.1px 41.8px 33.4px rgba(0, 0, 0, 0.05),
      60px 100px 80px rgba(0, 0, 0, 0.07);
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    border-left: solid #00818f;
    border-right: solid #00818f;
  }

  .innerContainer {
    padding-left: 2rem;
    padding-right: 2rem;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-justify-content: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .arrowUp {
    padding-top: 5px;
  }

  .collapseButton {
    overflow: hidden;
    cursor: pointer;
    width: 100%;
    background-color: #00818f;
    border: none;
    padding-left: 2rem;
    padding-right: 2rem;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-justify-content: space-between;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .topField {
    color: black;
    background-color: #f0ebeb;
    width: 100%;
    height: 100%;
    padding: 1rem 2rem;
  }

  .area {
    font-size: large;
    color: white;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .variable {
    font-size: medium;
  }
  .value {
    font-size: medium;
  }

  .collapsed {
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-justify-content: center;
    display: flex;
    justify-content: center;

    align-items: center;
    flex-direction: column;
    background-color: #f0ebeb;
    width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sidebyside {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    -webkit-justify-content: space-between;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .scroll {
    width: 95%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    scroll-margin-left: 20px;
    padding: 0rem 1rem 0rem 0rem;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #66b3bc;
    border-radius: 8px;
  }

  @media (max-width: 1440px) {
    .topField {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }
    .variable {
      font-size: medium;
    }
  }

  @media (max-height: 700px) {
    h1.area {
      font-size: medium;
    }
    .variable {
      font-size: small;
    }
    .value {
      font-size: small;
    }
  }

  @media (max-width: 770px) {
    h1.area {
      font-size: small;
    }
    .topField {
      padding-top: 0.1rem;
      padding-bottom: 0.1rem;
    }
    .innerContainer {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    .variable {
      font-size: small;
    }
    .value {
      font-size: small;
    }
  }
</style>
