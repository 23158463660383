import * as d3 from "d3";
import { omitKeys } from "./omitKeys.js";

export const createAxisInterval = (interval, axisValues) => {
  let reduceMax;
  if (axisValues.map((value) => typeof value === "object").includes(true)) {
    const toBeRemovedKeys = [
      ...new Set(
        axisValues
          .map((value) => Object.keys(value))
          .flat()
          .filter(
            (item) =>
              item.match(/[a-zA-Z]+Name[a-zA-Z]*/) ||
              item.match(/[a-zA-Z]+Title[a-zA-Z]*/)
          )
      ),
    ];
    const values = axisValues.map((value) =>
      Object.values(omitKeys(value, toBeRemovedKeys))
    );
    reduceMax = values.reduce(
      (prev, current) =>
        d3.max(Object.values(prev)) >= d3.max(Object.values(current))
          ? prev
          : current,
      values[0]
    );
  } else {
    reduceMax = axisValues;
  }
  try {
    const top = Math.ceil(d3.max(reduceMax) / interval);
    const array = [...Array(top + 1).keys()].map((value) => value * interval);
    return array;
  } catch (err) {
    console.error(
      "Problem while making an interval array: " +
        err.message +
        "\n Please check if the type of (axis) value is supported!"
    );
  }
};

export const createPercentalInterval = () => {
  return [...Array(11).keys()].map((value) => value * 10);
};
