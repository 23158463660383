import { derived, writable } from "svelte/store";
import {
  getVariableLevel,
  isValidArea,
  isValidId,
} from "../api/townshipAPI.js";

const urlParams = new URLSearchParams(window.location.search);

const formatArea = () => {
  if (urlParams.has("area")) {
    const areaQuery = urlParams.get("area");
    const UpperCase = areaQuery
      .split("-")
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
      .join("-");
    return isValidArea(UpperCase) ? UpperCase : "";
  } else return "";
};

const formatVar = () => {
  if (urlParams.has("var")) {
    const varQuery = urlParams.get("var");
    return isValidId(parseFloat(varQuery)) ? parseFloat(varQuery) : 305;
  } else return 305;
};

const area = formatArea();
const variable = formatVar();

export const selectedArea = writable(area);

export const selectedVar = writable(variable);

export const selectedLevel = derived(selectedVar, ($selectedVar) =>
  getVariableLevel($selectedVar)
);
