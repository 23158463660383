<script>
  import Navbar from "./componenten/navbar/Navbar.svelte";
  import Title from "./componenten/Title.svelte";
  import Map from "./componenten/map/Map.svelte";
  import CollapseWindow from "./componenten/areainfo/CollapseWindow.svelte";
  import DropdownBox from "./componenten/DropdownBox.svelte";
  import { getGraphComponent } from "./utils/getGraphComponent.js";
  import { selectedArea, selectedVar, selectedLevel } from "./stores/stores.js";
  import Loading from "./componenten/Loading.svelte";
  import {
    getDataByArea,
    getDataByVariable,
    getAllVariables,
  } from "./api/townshipAPI.js";
  import { getMap } from "./api/mapAPI.js";
  import Scatterplot from "./componenten/charts/Scatterplot.svelte";
  import { onMount } from "svelte";
  import { omitKeys } from "./utils/omitKeys";

  let graphData,
    areaInfo,
    graph,
    geoData,
    cheight,
    selectedColumn,
    windowWidth,
    data;
  let selectedObjectInfo = {};

  const urlParams = new URLSearchParams(window.location.search);
  const checkIfUrlParams = urlParams.has("area") || urlParams.has("var");
  const initData = async () => {
    const csvData = await d3.csv("./data/data-kinderopvang.csv");
    if (csvData) {
      const regex = new RegExp(/^-?\d+\.?\d*$/);
      const result = Object.values(omitKeys(csvData, ["columns"]));
      const parsed = result.map((entry) => {
        const keys = Object.keys(entry);
        const newObject = {};
        keys.reduce((acc, current) => {
          acc[current] = regex.test(entry[current])
            ? Number(entry[current])
            : entry[current];
          return acc;
        }, newObject);
        return newObject;
      });
      graphData = await getDataByVariable($selectedVar, parsed);
      areaInfo = await getDataByArea($selectedArea, parsed);
      reloadGraph(graphData);
      return parsed;
    }
  };
  const navbarData = getAllVariables();

  const reloadGraph = async (dataset) => {
    if (dataset) {
      selectedObjectInfo = Array.isArray(dataset)
        ? dataset[0].objectInfo
        : dataset.objectInfo;
      graph = getGraphComponent(selectedObjectInfo.grafiektype);
      if (Array.isArray(dataset)) {
        selectedColumn = dataset[0].objectInfo.kolomnaam;
      }
    }
  };

  const handleDropDownChange = (e) => {
    selectedColumn = e.detail.column;
  };
  onMount(async () => {
    data = await initData();
    selectedVar.subscribe(async (value) => {
      try {
        graphData = await getDataByVariable(value, data);
        reloadGraph(graphData);
      } catch (error) {
        console.error(error);
      }
    });
    selectedArea.subscribe(async (value) => {
      try {
        areaInfo = await getDataByArea(value, data);
      } catch (error) {
        console.error(error.message);
      }
    });

    selectedLevel.subscribe(async (value) => {
      try {
        if (value) {
          if (!checkIfUrlParams) {
            selectedArea.set("");
          }
          geoData = getMap(value);
        }
      } catch (error) {
        console.error(error.message);
      }
    });

    if (windowWidth <= 800) {
      alert(
        "Deze site is gemaakt met grotere schermen in gedachten.\n\nVoor een betere gebruikerservaring raden wij aan om deze site te gebruiken op een laptop."
      );
    }
  });
</script>

<svelte:window bind:innerWidth={windowWidth} />
<main bind:clientHeight={cheight}>
  {#await initData()}
    <Loading />
  {:then}
    <Navbar areaInfo={navbarData} />
    <div class="hcontainer">
      <div class="vcontainer">
        <div
          class="screen"
          style="flex-shrink: 1; flex-grow: 0; padding-bottom: 1rem"
        >
          <Title>
            <div slot="title">{selectedObjectInfo.langeNaam}</div>
            <div slot="subtitle">
              {selectedObjectInfo.omschrijving}
            </div>
          </Title>
        </div>
        <div class="screen graph">
          {#key graphData}<svelte:component
              this={graph}
              data={graphData}
            />{/key}
        </div>
      </div>

      <div class="vcontainer">
        <div class="screen map">
          {#await reloadGraph then}
            {#key graphData || $selectedArea || $selectedLevel || selectedColumn}<Map
                data={graphData}
                useDefaultColor={graph === Scatterplot}
                {geoData}
                {selectedColumn}
              />{/key}
          {/await}
        </div>
        {#key graphData}<div
            class="screen dropdown"
            style="justify-content: start; align-items: flex-start; padding-right: 10px;"
          >
            {#if Array.isArray(graphData) && graph != Scatterplot && $selectedLevel != "Gewest"}<DropdownBox
                on:change={handleDropDownChange}
                options={graphData.map((item) => item.objectInfo.kolomnaam)}
              />{/if}
          </div>{/key}
        <div class="collapsewindow">
          <CollapseWindow data={areaInfo} maxheight={cheight} />
        </div>
      </div>
    </div>
  {/await}
</main>

<style>
  main {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    background-color: #f0ebeb;
    -webkit-justify-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .hcontainer {
    padding-left: 69px;
    padding-right: 69px;
    max-width: 1600px;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    -webkit-justify-content: space-around;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    background-color: white;
  }

  .vcontainer {
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
  }

  .screen {
    height: 100%;
    width: 100%;
    min-width: 750px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: fit-content;
  }

  .collapsewindow {
    flex-shrink: 1;
    position: relative;
    width: 75%;
    height: fit-content;
    z-index: 10;
    bottom: 0;
    right: 1rem;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-self: center;
  }

  .dropdown {
    width: 90%;
    height: 50%;
    flex-shrink: 1;
  }

  .map {
    height: 100%;
    flex-grow: 1;
  }

  @media (max-width: 1600px) and (min-width: 1441px) {
    .hcontainer {
      max-width: 1600px;
      width: 100%;
      padding-left: 0px;
      padding-right: 0px;
    }

    .graph {
      padding-left: 34px;
    }

    .screen {
      min-width: 375px;
    }

    .dropdown {
      height: 100%;
      flex-shrink: 2;
    }

    .map {
      height: 100%;
      flex-grow: 2;
    }
  }

  @media (max-width: 1440px) {
    .hcontainer {
      max-width: 1600px;
    }

    .screen {
      min-width: 100%;
    }
  }

  @media (max-width: 1024px) {
    .screen {
      min-width: 0px;
    }
  }

  @media (max-width: 770px) {
    main {
      max-width: none;
    }

    .hcontainer {
      max-width: none;
      flex-direction: column;
      overflow: scroll;
      padding-left: 20px;
      padding-right: 20px;
    }
    .vcontainer {
      justify-content: flex-start;
    }
    .dropdown {
      height: 250px;
    }

    .collapsewindow {
      right: 0;
      width: 100%;
    }

    div.graph {
      display: none;
      height: 0px;
    }
  }
</style>
