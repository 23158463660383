<script>
  import { onMount } from "svelte";
  import { selectedArea } from "../../stores/stores.js";
  import * as d3 from "d3";
  import { alignTextToRight } from "../../utils/alignTextToRight.js";
  import { centerTextToGraph } from "../../utils/centerTextToGraph.js";
  import { staticPalette } from "../../utils/staticPalette.js";
  import { addAdfix } from "../../utils/addAdfix.js";
  export let data;

  let cheight, cwidth, svgRef, windowWidth;
  const adfix = data[0].objectInfo.adfix;

  const getFormatedValues = () => {
    const dataObjects = data
      .sort((a, b) => b.kolomnummer - a.kolomnummer)
      .map((entry) => entry.values);
    const result = dataObjects.reduce((acc, current, currentIndex) => {
      if (acc.length === 0) {
        return current;
      }
      const entries = acc.map((value) => {
        const found = current.find((element) => element.name === value.name);
        return found
          ? {
              ...value,
              value: [value.value, found.value].flat(2),
            }
          : {
              ...value,
              value: [value.value, 0].flat(2),
            };
      });
      const notFound = current.filter((entry) => {
        return !entries.map((value) => value.name).includes(entry.name);
      });

      if (notFound.length <= 0) {
        return entries;
      } else {
        const newEntries = notFound.map((value) => {
          const filler = [...Array(currentIndex).keys()].map(() => 0);
          return { ...value, value: [...filler, value.value] };
        });
        return [...entries, ...newEntries];
      }
    }, []);
    return result;
  };

  const dataset =
    data[0].objectInfo.id === 235
      ? getFormatedValues().sort(
          (a, b) =>
            b.value.reduce((acc, current) => (acc = acc + current), 0) -
            a.value.reduce((acc, current) => (acc = acc + current), 0)
        )
      : getFormatedValues().sort((a, b) => a.name - b.name);
  const arraySum = (list) => {
    return list.reduce((acc, current) => {
      const sum = acc + current;
      return sum;
    }, 0);
  };

  const getDomain = () => {
    const values = getFormatedValues().map((element) => element.value);
    const max = values.reduce((acc, current) => {
      let newElement = current;
      if (!Array.isArray(current)) {
        newElement = [current];
      }
      return arraySum(acc) >= arraySum(newElement) ? acc : current;
    }, values[0]);

    return arraySum(max);
  };

  const renderBars = (currentIndex, svg, x, y) => {
    if (dataset[currentIndex].value.length <= currentIndex) {
      return;
    }
    const colors = staticPalette(dataset[currentIndex].value);
    if (svg) {
      const bar = svg
        .selectAll(".bar")
        .append("rect")
        .attr("class", `rectBar${currentIndex}`);
      bar
        .style("cursor", "pointer")
        .attr("x", x(0))
        .attr("y", (d) => y(d.name))
        .attr("height", y.bandwidth())
        .attr("fill", colors[currentIndex].color)
        .attr("value", (d) => d.name)
        .on("mouseover", (d) =>
          hanldeMouseOver(currentIndex, svg, colors[currentIndex], x)
        )
        .on("mouseleave", () =>
          handleMouseLeave(currentIndex, svg, colors[currentIndex])
        )
        .on("click", (e, d) => handleClick(d.name));
      bar
        .transition()
        .duration(500)
        .attr("width", (d) => x(d.value[currentIndex]))
        .attr("x", (d) => {
          if (currentIndex === 0) {
            return x(0);
          }
          const xvalue = d.value
            .slice(0, currentIndex)
            .reduce((acc, current) => acc + current, 0);
          return x(xvalue);
        });
    }

    renderBars(currentIndex + 1, svg, x, y);
  };

  const colorSelectedArea = (svg) => {
    const bar = svg.selectAll(".bar").select(function () {
      return this.getAttribute("value") === $selectedArea ? this : "";
    });

    svg.selectAll(".rightValueLabel").style("font-weight", (d) => {
      return d.name === $selectedArea ? "bold" : "normal";
    });
    svg.selectAll(".arealabel").style("font-weight", (d) => {
      return d.name === $selectedArea ? "bold" : "normal";
    });
    const list = bar.data().pop();
    if (list) {
      const colors = staticPalette(list.value);
      if (bar) {
        svg
          .selectAll(".bar")
          .selectAll("rect")
          .style("fill", (d, i) => {
            return d.name === $selectedArea
              ? colors[i].highlight
              : colors[i].color;
          });
      }
    }
  };

  const hanldeMouseOver = (index, svg, color, x) => {
    svg.selectAll(".legendHelp").style("opacity", 0);
    svg.selectAll(".rectBar" + index).attr("stroke", "#333");
    svg
      .selectAll(".rightValueLabel")
      .text((d) =>
        addAdfix(adfix, parseFloat(d.value[index]).toLocaleString())
      );

    svg
      .selectAll(".legendText")
      .text(data[index].objectInfo.kolomnaam)
      .attr("x", function () {
        return x(getDomain()) - this.getBBox().width;
      });
    svg
      .selectAll(".legendDot")
      .attr("r", 6)
      .attr("cx", function () {
        return (
          x(getDomain()) -
          this.getBBox().width -
          svg.selectAll(".legendText").node().getBBox().width
        );
      })
      .style("fill", color.color);
  };

  const handleMouseLeave = (index, svg, color) => {
    svg.selectAll(".legendHelp").style("opacity", 1);
    svg
      .selectAll(".rectBar" + index)
      .style("fill", (d) => {
        return d.name === $selectedArea ? color.highlight : color.color;
      })
      .attr("stroke", "transparant");

    svg.selectAll(".rightValueLabel").text("");
    svg.selectAll(".legendDot").attr("r", 0);
    svg.selectAll(".legendText").text("");
  };

  const handleClick = (value) => {
    selectedArea.set(value);
  };

  onMount(() => {
    const margin = {
      top: 30,
      right: 70,
      bottom: 20,
      left: windowWidth > 1440 ? 200 : 160,
    };
    const width = cwidth - margin.left - margin.right;
    const height = cheight;

    const svg = d3
      .select(svgRef)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Add X axis
    const x = d3.scaleLinear().domain([0, getDomain()]).range([0, width]);
    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(5)
          .tickFormat((d) => addAdfix(adfix, d))
      );
    svg.select(".domain").attr("stroke", "transparant");

    // Y axis
    const y = d3
      .scaleBand()
      .range([0, height])
      .domain(dataset.map((d) => d.name))
      .padding(0.2);
    //Raster
    const power = 10 ** (`${Math.floor(getDomain())}`.split("").length - 2) * 2;
    const arrayLines = [...Array(Math.ceil(getDomain() / power)).keys()].map(
      (value) => value * power
    );
    svg
      .selectAll("myline")
      .data(arrayLines)
      .join("line")
      .attr("x1", (d) => x(d))
      .attr("x2", (d) => x(d))
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke", "#bababa")
      .attr("stroke-width", "1px");

    // First value
    svg
      .selectAll("myRect")
      .data(dataset)
      .join("g")
      .attr("class", "bar")
      .attr("value", (d) => d.name);
    renderBars(0, svg, x, y);

    const filler = svg
      .selectAll(".bar")
      .append("rect")
      .attr("class", "filler")
      .attr("y", (d) => y(d.name))
      .attr("x", x(0))
      .attr("height", y.bandwidth())
      .attr("width", x(0));

    filler
      .transition()
      .duration(500)
      .attr("x", (d) => {
        const xvalue = d.value
          .slice(0, d.value.length)
          .reduce((acc, current) => acc + current, 0);
        return x(xvalue);
      })
      .attr("width", (d) => {
        const width =
          getDomain() -
          d.value
            .slice(0, d.value.length)
            .reduce((acc, current) => acc + current, 0);

        return width >= 0 ? x(width) : x(0);
      })
      .attr("fill", (d) => {
        const colors = staticPalette(d.value);
        const grey = colors.find((entry) => entry.highlight === "");
        return grey ? grey.color : "transparant";
      });

    // Labels

    svg.append("g").attr("class", "labels");

    svg.append("g").attr("class", "valueLabels");

    svg
      .selectAll(".valueLabels")
      .selectAll("text")
      .data(dataset)
      .join("g")
      .style(
        "transform",
        windowWidth > 1440 ? "translate(10px, 0px)" : "translate(10px, 0px)"
      )
      .append("text")
      .attr("class", "rightValueLabel")
      .attr("x", x(getDomain()))
      .attr("y", (d) => y(d.name) + centerTextToGraph(svg, ".rectBar0"))
      .style("font-weight", "regular")
      .style("font-family", "Lato")
      .style("font-size", windowWidth > 1440 ? "medium" : "small")

      .style("cursor", "pointer")
      .style("alignment-baseline", "central")
      .style("dominant-baseline", "hanging")
      .text("");

    svg
      .selectAll(".labels")
      .selectAll("text")
      .data(dataset)
      .join("g")
      .style("transform", (d) => {
        const name = d.name;
        if (windowWidth > 1440) {
          return /[0-9]+/.test(name)
            ? "translate(-60px, 0px)"
            : "translate(-190px, 0px)";
        } else
          return /[0-9]+/.test(name)
            ? "translate(-40px, 0px)"
            : "translate(-150px, 0px)";
      })
      .append("text")
      .attr("class", "arealabel")
      .attr("x", x(0))
      .attr("y", (d) => y(d.name) + centerTextToGraph(svg, ".rectBar0"))
      .style("font-family", "Lato")
      .style("font-size", windowWidth > 1440 ? "medium" : "small")
      .style("fill", "#333")
      .style("cursor", "pointer")
      .style("alignment-baseline", "central")
      .style("dominant-baseline", "hanging")
      .text((d) => d.name)
      .on("click", (e, d) => handleClick(d.name));

    svg.selectAll(".arealabel").attr("x", function () {
      return alignTextToRight(svg, ".arealabel", this);
    });

    // Legend

    const legend = svg.append("g").attr("class", "legend");
    legend
      .append("circle")
      .attr("class", "legendDot")
      .attr("cx", x(getDomain()))
      .attr("cy", 0)
      .attr("r", 0)
      .style("fill", "#404080")
      .style("transform", "translate(0px, -15px)")
      .style("alignment-baseline", "central");

    legend
      .append("text")
      .attr("class", "legendText")
      .text("")
      .attr("x", x(getDomain()))
      .attr("y", 0)
      .style("transform", "translate(0px, -15px)")
      .style("font-size", "15px")
      .style("font-size", windowWidth > 1440 ? "medium" : "small")
      .style("alignment-baseline", "central")
      .style("dominant-baseline", "middle");

    legend
      .append("text")
      .attr("class", "legendHelp")
      .attr("x", x(5))
      .attr("y", y(height))
      .style("transform", "translate(0px, -15px)")
      .style("font-style", "italic")
      .style("font-size", "small")
      .text("Hover over een balk om de data te bekijken");

    selectedArea.subscribe(() => colorSelectedArea(svg));
  });
</script>

<svelte:window bind:innerWidth={windowWidth} />

<div bind:clientHeight={cheight} bind:clientWidth={cwidth} class="container">
  <div bind:this={svgRef} class="chart" />
</div>

<style>
  .container {
    min-height: 85%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart {
    display: flex;
    flex-grow: 1;
    justify-content: right;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
    padding: 0rem 1rem;
  }
  @media (min-width: 1440px) {
    .chart {
      height: 1000px;
    }
  }

  @media (min-width: 1024px) {
    .chart {
      height: 300px;
    }
  }

  @media (min-width: 770px) {
    .chart {
      height: 200px;
    }
  }
</style>
