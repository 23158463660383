<script>
  import List from "./partials/List.svelte";
  import { getEventsAction } from "../../../utils/getEventsAction";
  import { current_component } from "svelte/internal";
  import { fade } from "svelte/transition";
  import { clickOutside } from "../../../utils/clickOutside";
  export let toggleIsOpen = () => {};
  export let insertedData;

  const iconURLs = [
    "./svg/BXLKinderopvang_icons-01.svg",
    "./svg/BXLKinderopvang_icons-02.svg",
    "./svg/BXLKinderopvang_icons-03.svg",
  ];

  const events = getEventsAction(current_component);
  const handleClickOutside = () => {
    toggleIsOpen();
  };
</script>

<div class="flexcontainer">
  <div
    class="container"
    transition:fade={{ duration: 100 }}
    use:clickOutside
    on:click_outside={handleClickOutside}
  >
    <div class="inner" use:events>
      {#each insertedData as list, index}
        <List
          dataset={[list.shortNames, list.ids]}
          title={list.category}
          icon={iconURLs[index]}
          isDisabled={list.isDisabled}
          {toggleIsOpen}
        />
      {/each}
    </div>
  </div>
</div>

<style>
  .flexcontainer {
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .container {
    border: solid;
    z-index: 25;
    background-color: white;
    border-color: #00818f;
    border-top: 0rem;
    position: absolute;
    top: 0;
    width: 1738px;
    display: flex;
    justify-content: center;
    -webkit-justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .inner {
    height: 100%;
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media (max-width: 1440px) {
    .container {
      width: 99%;
      top: 12%;
    }
  }
  @media (max-width: 1024px) {
    .container {
      width: 100%;
      top: 17%;
    }
  }

  @media (max-width: 770px) {
    .container {
      width: 100%;
      top: 17%;
    }
  }

  @media (max-height: 880px) {
    .container {
      width: 70%;
      left: 25%;
    }
  }
</style>
