const palettes = (count) => {
  switch (count) {
    case 1:
      return [
        { color: "#00818f", highlight: "#ff3600" },
        { color: "#f2f2f2", highlight: "" },
      ];
    case 2:
      return [
        { color: "#00818f", highlight: "#ff3600" },
        { color: "#66b3bc", highlight: "#ff8666" },
        { color: "#f2f2f2", highlight: "" },
      ];
    case 3:
      return [
        { color: "#00818f", highlight: "#ff3600" },
        { color: "#339aa5", highlight: "#ff5e33" },
        { color: "#66b3bc", highlight: "#ff8666" },
        { color: "#f2f2f2", highlight: "" },
      ];
    case 4:
      return [
        { color: "#00818f", highlight: "#ff3600" },
        { color: "#339aa5", highlight: "#ff5e33" },
        { color: "#66b3bc", highlight: "#ff8666" },
        { color: "#99cdd2", highlight: "#ffaf99" },
        { color: "#f2f2f2", highlight: "" },
      ];
    case 5:
      return [
        { color: "#00616b", highlight: "#bf2900" },
        { color: "#00818f", highlight: "#ff3600" },
        { color: "#339aa5", highlight: "#ff5e33" },
        { color: "#66b3bc", highlight: "#ff8666" },
        { color: "#99cdd2", highlight: "#ffaf99" },
        { color: "#f2f2f2", highlight: "" },
      ];
    case 6:
      return [
        { color: "#004148", highlight: "#801b00" },
        { color: "#00616b", highlight: "#bf2900" },
        { color: "#00818f", highlight: "#ff3600" },
        { color: "#339aa5", highlight: "#ff5e33" },
        { color: "#66b3bc", highlight: "#ff8666" },
        { color: "#99cdd2", highlight: "#ffaf99" },
        { color: "#f2f2f2", highlight: "" },
      ];
    default:
      return [{ color: "#00818f", highlight: "#ff3600" }];
  }
};

export const staticPalette = (valueList = []) => {
  const count = valueList.length > 1 ? valueList.length : 1;
  return palettes(count);
};
