import * as d3 from "d3";

export const treshPalette = (min, max) => {
  const d = (max - min) / 25;
  return d3
    .scaleThreshold()
    .range(['#f7f7f7', '#e8f0f0', '#d9e9ea', '#cae1e4', '#bbdade', '#acd3d7', '#9dccd1', '#8ec5cb', '#7fbdc4', '#6fb6be', '#60afb7', '#50a7b1', '#40a0aa', '#3098a3', '#20909c', '#0f8895', '#00808e', '#007885', '#006f7c', '#006773', '#00606a', '#005861', '#005059', '#004850', '#004148'])
    .domain([
      min + d * 1,
      min + d * 2,
      min + d * 3,
      min + d * 4,
      min + d * 5,
      min + d * 6,
      min + d * 7,
      min + d * 8,
      min + d * 9,
      min + d * 10,
      min + d * 11,
      min + d * 12,
      min + d * 13,
      min + d * 14,
      min + d * 15,
      min + d * 16,
      min + d * 17,
      min + d * 18,
      min + d * 19,
      min + d * 20,
      min + d * 21,
      min + d * 22,
      min + d * 23,
      min + d * 24
    ]);
};
