<script>
  import { getEventsAction } from "../../../utils/getEventsAction";
  import { current_component } from "svelte/internal";
  import { fade } from "svelte/transition";
  import { clickOutside } from "../../../utils/clickOutside";
  import { selectedVar, selectedArea } from "../../../stores/stores.js";
  import { onMount } from "svelte";
  export let blurShare = () => {};

  let defaultUrl, urlQuery, textField, button, icon;

  const events = getEventsAction(current_component);
  const handleClickOutside = () => {
    blurShare();
  };

  const getCurrentFormatedUrl = (value) => {
    const variable = "var=" + $selectedVar;
    const area = value === "" ? "" : "area=" + value + "&";
    return `${defaultUrl}?${area}${variable}`;
  };

  const showChange = (value) => {
    urlQuery = "URL gekopieerd!";
    setTimeout(() => {
      urlQuery = value;
    }, 1500);
  };

  const handleClick = () => {
    textField.select();
    document.execCommand("copy");
    button.setAttribute("style", "background: lightgreen;");
    icon.setAttribute("src", "./svg/checked.svg");
    icon.setAttribute("style", "filter: invert(1); width: 20px; height: 20px;");
    showChange(urlQuery);
  };

  onMount(() => {
    defaultUrl = location.protocol + "//" + location.host + location.pathname;
    selectedArea.subscribe((value) => {
      urlQuery = getCurrentFormatedUrl(value);
    });
  });
</script>

<div class="flexcontainer">
  <div
    class="container"
    transition:fade={{ duration: 100 }}
    use:clickOutside
    on:click_outside={handleClickOutside}
  >
    <div class="inner" use:events>
      <input
        class="textField"
        readonly
        bind:this={textField}
        bind:value={urlQuery}
      />
      <button
        class="copyButton"
        bind:this={button}
        type="button"
        on:click={handleClick}
        ><img
          src="./svg/copy.svg"
          alt="copy.svg"
          bind:this={icon}
          height="20px"
          width="20px"
        /></button
      >
    </div>
  </div>
</div>

<style>
  button,
  input {
    margin: 5px;
  }

  .flexcontainer {
    display: flex;
    justify-content: right;
    -webkit-justify-content: flex-end;
    align-items: center;
    position: relative;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 1738px;
    margin-left: auto;
    margin-right: auto;
  }
  .container {
    border: solid;
    z-index: 25;
    background-color: white;
    border-color: #00818f;
    border-top: 0rem;
    position: absolute;
    top: 0;
    width: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
      rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .inner {
    height: 100%;
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .textField {
    display: flex;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    flex-grow: 2;
    outline: none;
  }

  img {
    padding: 2px;
  }

  .copyButton {
    display: flex;
    width: 45px;
    height: 35px;
    flex-shrink: 1;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
  }

  @media (max-width: 1600px) {
    .flexcontainer {
      justify-content: middle;
      width: 99%;
    }
  }

  @media (max-width: 770px) {
    .container {
      width: 75%;
    }
  }
</style>
