<script>
  export let data = [];
  import ListItem from "./ListItem.svelte";
  import { selectedVar, selectedArea } from "../../../stores/stores";
  import { addAdfix } from "../../../utils/addAdfix.js";

  const formatedValue = (item) => {
    const value = item.value ? parseFloat(item.value).toLocaleString() : "";
    if (Array.isArray(item)) {
      if (item[0].graph === "Dumbbell") {
        const [firstVal, secondVal] = item;
        const sum = secondVal.value - firstVal.value;
        const value =
          sum >= 0
            ? `${secondVal.value} (+${sum})`
            : `${secondVal.value} (${sum})`;
        const newVal = { ...item[0], value: $selectedArea === "" ? "" : value };
        return newVal;
      }

      if (item[0].graph === "Spreidingsdiagram") {
        const values = item.slice(-2);
        const [xVal, yVal] = values.map((item) => item.value);
        const newVal = {
          ...item[0],
          value: $selectedArea === "" ? "" : `${xVal} / ${yVal}`,
        };
        return newVal;
      }

      if (item[0].id === 235) {
        const sum = item.reduce((acc, current) => {
          acc = parseFloat(current.value + acc);
          return acc;
        }, 0);
        return { ...item[0], value: addAdfix(item[0].adfix, sum) };
      }
      return item.length > 0
        ? formatedValue(item.pop())
        : formatedValue(item[0]);
    }
    if (item.graph === "Gestapeld staafdiagram" && item.cat === "Aanvragen") {
      return { ...item, value: "" };
    }
    if (value === "" || !value) {
      return { ...item, value };
    }
    return { ...item, value: addAdfix(item.adfix, value) };
  };

  const dataset = data.map((value) => formatedValue(value));

  const handleClick = (item) => {
    const id = item.id;
    selectedVar.set(id);
  };
</script>

<div class="scroll">
  <div class="container">
    <h1 class="title"><slot name="title"><em>No title found</em></slot></h1>
    {#each dataset as item}
      <ListItem handleClick={() => handleClick(item)} label={item.shortName}
        >{item.graph === "Gestapeld staafdiagram" ? "" : item.value}</ListItem
      >
    {/each}
  </div>
</div>

<style>
  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    border-top: solid;
    border-color: #00616b;
    position: relative;
    padding-bottom: 20px;
  }

  .title {
    position: -webkit-sticky;
    position: sticky;
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    background-color: #f0ebeb;
    padding-bottom: 5px;
    top: 0;
    left: 0;
    font-weight: 600;
    font-size: large;
    text-align: left;
    font-family: "Poppins", sans-serif;
    color: #00616b;
    -webkit-margin-after: 0.4em;
    margin-block-end: 0.4em;
  }
</style>
