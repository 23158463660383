<script>
  export let dataset = [];
  export let title = "";
  export let icon = "";
  export let isDisabled = false;
  export let toggleIsOpen = () => {};
  import { selectedVar } from "../../../../stores/stores.js";
  const handleClick = (id) => {
    if (!isDisabled) {
      selectedVar.set(id);
      toggleIsOpen();
    }
  };
  const [shortNames, ids] = dataset;
</script>

<div class="container">
  <div class="icon">
    <img class="svg" src={icon} alt="icon" height={100} width={100} />
  </div>
  <div class:isDisabled><h1>{title}</h1></div>
  <div>
    {#each shortNames as item, index}
      <p on:click={() => handleClick(ids[index])}>
        {item}
      </p>
    {/each}
  </div>
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;
    position: relative;
    align-self: flex-start;
  }

  h1 {
    color: #03353b;
    padding: 1rem;
    padding-left: 0rem;
    font-family: "Poppins", sans-serif;
    font-size: x-large;
  }

  .icon {
    display: flex;
    position: absolute;
    top: 30px;
    left: 0px;
    z-index: -1;
    transform: translate(-15px, 0px);
  }

  .svg {
    fill: #2e767e;
    opacity: 90%;
  }

  p {
    margin: 5px;
    cursor: pointer;
  }
  p:hover {
    text-decoration: underline;
  }

  .isDisabled {
    opacity: 50%;
    cursor: not-allowed;
  }
  .isDisabled:hover {
    text-decoration: none;
  }

  @media (max-height: 880px) {
    h1 {
      font-size: large;
    }
    p {
      font-size: small;
    }
  }
</style>
