<script>
  import * as d3 from "d3";
  import { onMount } from "svelte";
  import { selectedArea } from "../../stores/stores.js";
  import { createAxisInterval } from "../../utils/createAxisInterval.js";

  export let data = [];
  let cheight, cwidth, svgRef, windowWidth;

  const getValuePairs = (list1, list2, combinedList = []) => {
    if (list1.lenght === 0 || list2.length === 0) {
      return combinedList;
    }
    const firstObject = list1.shift();
    const secondObject = list2.find(
      (element) => element.name === firstObject.name
    );
    if (!secondObject) {
      return;
    }
    const result = {
      name: firstObject.name,
      xValue: firstObject.value,
      yValue: secondObject.value,
    };
    combinedList.push(result);
    return getValuePairs(
      list1,
      list2.filter((value) => value != secondObject),
      combinedList
    );
  };

  const formatData = () => {
    const values = data.map((element) => element.values);
    const groupedArrays = values.reduce((acc, current, index) => {
      if ((index + 1) % 2 === 0) {
        const group = [values[index - 1], values[index]];
        acc.push(group);
      }
      return acc;
    }, []);
    const groupedValues = groupedArrays.map((group) => {
      const [firstObject, secondObject] = group;
      const valuepairs = getValuePairs(firstObject, secondObject);
      return valuepairs;
    });

    const getLegend = (xAxis, yAxis) => {
      const x = xAxis.split(" ").filter((element) => element.match(/\d{4}/));
      const y = yAxis.split(" ").filter((element) => element.match(/\d{4}/));
      return x === y ? x : d3.max([x, y]);
    };
    const dataObjects = data.reduce((acc, element, index) => {
      const {
        id,
        korteNaam,
        langeNaam,
        niveau,
        omschrijving,
        categorie,
        grafiektype,
      } = element.objectInfo;
      if ((index + 1) % 2 === 0) {
        const newObject = {
          id,
          korteNaam,
          langeNaam,
          niveau,
          omschrijving,
          categorie,
          grafiektype,
          xAxis: data[index - 1].objectInfo.kolomnaam,
          yAxis: data[index].objectInfo.kolomnaam,
          values: groupedValues.shift(),
          year: getLegend(
            data[index - 1].objectInfo.kolomnaam,
            data[index].objectInfo.kolomnaam
          )
            ? getLegend(
                data[index - 1].objectInfo.kolomnaam,
                data[index].objectInfo.kolomnaam
              )
            : "",
        };
        acc.push(newObject);
      }
      return acc;
    }, []);
    return dataObjects;
  };

  const dataset = formatData();

  const getDomain = (roundTo = 10) => {
    const xValues = dataset.map((element) =>
      element.values.map((value) => value.xValue)
    );
    const max = xValues.reduce(
      (acc, current) => (acc >= d3.max(current) ? acc : d3.max(current)),
      0
    );
    const min = xValues.reduce(
      (acc, current) => (acc <= d3.min(current) ? acc : d3.min(current)),
      max
    );
    return [
      Math.floor(min / roundTo) * roundTo,
      Math.ceil(max / roundTo) * roundTo,
    ];
  };

  const getRange = (roundTo = 50) => {
    const yValues = dataset.map((element) =>
      element.values.map((value) => value.yValue)
    );
    const max = yValues.reduce(
      (acc, current) => (acc >= d3.max(current) ? acc : d3.max(current)),
      0
    );
    return [0, Math.ceil(max / roundTo) * roundTo];
  };

  const renderConnections = (svg, x, y) => {
    const values = dataset.map((value) => value.values).flat();
    const groupDots = (toGroupList, sumList = []) => {
      if (toGroupList.length === 0) {
        return sumList;
      }
      const searchValue = toGroupList.shift();
      const foundValues = toGroupList.filter(
        (value) => value.name === searchValue.name
      );
      sumList.push([searchValue, ...foundValues]);
      return groupDots(
        toGroupList.filter((value) => value.name != searchValue.name),
        sumList
      );
    };
    const groupedDots = groupDots(values);
    svg.append("g").attr("class", "connections");
    const generateLines = (array) => {
      if (array.length != 0) {
        const firstValue = array.shift();
        const secondValue = array[0];

        if (secondValue) {
          const line = svg.selectAll(".connections").append("line");
          line
            .attr("x1", x((firstValue.xValue + secondValue.xValue) / 2))
            .attr("x2", x((firstValue.xValue + secondValue.xValue) / 2))
            .attr("y1", y((firstValue.yValue + secondValue.yValue) / 2))
            .attr("y2", y((firstValue.yValue + secondValue.yValue) / 2))
            .attr("connection", firstValue.name)
            .attr("stroke", "#bababa")
            .attr("stroke-width", "0.5px");

          line
            .transition()
            .duration(1500)
            .delay(500)
            .attr("x1", x(firstValue.xValue))
            .attr("x2", x(secondValue.xValue))
            .attr("y1", y(firstValue.yValue))
            .attr("y2", y(secondValue.yValue))
            .attr("stroke", "#bababa")
            .attr("stroke-width", "1.5px");
          return generateLines(array);
        }
      }
    };
    groupedDots.forEach((element) => {
      generateLines(element);
    });
  };

  const renderDots = (svg, x, y) => {
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    dataset.forEach((element, index) => {
      // Add dots
      svg.append("g").attr("class", `groupDots${index + 1}`);
      const dots = svg
        .selectAll(`.groupDots${index + 1}`)
        .selectAll("circles")
        .data(element.values)
        .join("circle")
        .attr("cx", x(0))
        .attr("cy", y(0))
        .attr("r", 5)
        .style("fill", () => {
          return color(index);
        });

      dots
        .transition()
        .delay((d, i) => {
          return i * 2 * (index + 1);
        })
        .duration(1500)
        .attr("cx", (d) => {
          return x(d.xValue);
        })
        .attr("cy", (d) => {
          return y(d.yValue);
        });
    });
  };

  const getHiglightedDot = (svg) => {
    const highlighedDot = svg
      .selectAll(".groupDots2")
      .selectAll("circle")
      .data()
      .find((element) => element.name === $selectedArea);
    return highlighedDot;
  };

  const colorSelectedValues = (
    svg,
    x,
    y,
    horizontal,
    vertical,
    xVal,
    yVal,
    xBackground,
    yBackground,
    helpName
  ) => {
    const highlighedDot = getHiglightedDot(svg);
    svg
      .selectAll(".connections")
      .selectAll("line")
      .style("stroke", function (d) {
        const selector = d3.select(this).attr("connection");
        return selector === $selectedArea ? "#f29191" : "#bababa";
      });
    svg
      .selectAll(".groupDots1")
      .selectAll("circle")
      .style("fill", (d) => {
        return d.name === $selectedArea ? "#f29191" : "#b5b3b3";
      });
    svg
      .selectAll(".groupDots2")
      .selectAll("circle")
      .style("fill", (d) => {
        return d.name === $selectedArea ? "red" : "#124c53";
      });

    vertical
      .transition()
      .duration(750)
      .attr("x1", x(highlighedDot?.xValue))
      .attr("x2", x(highlighedDot?.xValue))
      .attr("y1", y(0))
      .attr("y2", y(highlighedDot ? highlighedDot.yValue : 0));

    horizontal
      .transition()
      .duration(750)
      .attr("x1", x(getDomain()[0]))
      .attr("x2", x(highlighedDot?.xValue))
      .attr("y1", y(highlighedDot?.yValue))
      .attr("y2", y(highlighedDot?.yValue));

    helpName
      .transition()
      .duration(750)
      .attr("x", x(highlighedDot?.xValue + 2))
      .attr("y", y(highlighedDot?.yValue + 2))
      .text(highlighedDot?.name);

    xVal.transition().duration(750).attr("dx", x(highlighedDot?.xValue));
    const newX = xVal
      .text(highlighedDot?.xValue)
      .style("transform", function () {
        const height = this.getBBox().height;
        return `translate(0px,${height}px)`;
      });

    yVal.transition().duration(750).attr("dy", y(highlighedDot?.yValue));

    const newY = yVal
      .text(highlighedDot?.yValue)
      .style("transform", function () {
        const width = this.getBBox().width;
        return `translate(-${width + 5}px,0px)`;
      });

    xBackground.transition().duration(750).attr("x", x(highlighedDot?.xValue));
    xBackground.attr("width", newX.node().getBBox().width);

    yBackground.transition().duration(750).attr("y", y(highlighedDot?.yValue));
    yBackground.attr("width", newY.node().getBBox().width);
  };

  onMount(() => {
    // set the dimensions and margins of the graph
    const margin = {
        top: 60,
        right: windowWidth > 1440 ? 110 : 90,
        bottom: 60,
        left: windowWidth > 1440 ? 100 : 75,
      },
      width = cwidth - margin.left - margin.right,
      height = cheight - margin.top;
    // append the svg object to the body of the page
    const svg = d3
      .select(svgRef)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Add X axis
    const x = d3.scaleLinear().domain(getDomain(10)).range([0, width]);
    svg
      .append("g")
      .attr("class", "myXaxis") // Note that here we give a class to the X axis, to be able to call it later and modify it
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    // Add Y axis
    const y = d3.scaleLinear().domain(getRange(50)).range([height, 0]);
    svg.append("g").call(d3.axisLeft(y));

    // Axis labels

    svg.append("g").attr("class", "axisLabels");
    const gggIKT = svg.selectAll(".axisLabels").append("g");
    gggIKT
      .append("text")
      .text("Gestandaardiseerde gemeentedekkingsgraad IKT")
      .style("font-weight", "bold")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");

    gggIKT.style("transform", function () {
      const width = gggIKT.selectAll("text").node().getBBox().width;
      return `rotate(270deg) translate(-${
        windowWidth > 1440 ? width : width - 70
      }px, -50px)`;
    });

    const ggiIKT = svg.selectAll(".axisLabels").append("g");
    ggiIKT
      .append("text")
      .text("Gemiddeld gestandaardiseerd inkomen")
      .attr("x", x(getDomain()[1]))
      .attr("y", y(0))
      .style("font-weight", "bold")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");

    ggiIKT.style("transform", function () {
      const width = ggiIKT.selectAll("text").node().getBBox().width;
      return `translate(-${windowWidth > 1440 ? width : width - 70}px, 40px)`;
    });

    const li = svg
      .selectAll(".axisLabels")
      .append("g")
      .style(
        "transform",
        () => `translate(${windowWidth > 1440 ? 10 : 0}px, -5px)`
      );
    li.append("text")
      .text("← lagere inkomens")
      .attr("x", x(getDomain()[0]))
      .attr("y", y(getRange()[1]))
      .style("fill", "#9e9e9e")
      .style("font-style", "italic")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");

    const hi = svg.selectAll(".axisLabels").append("g");
    hi.append("text")
      .text("hogere inkomens →")
      .attr("x", x(getDomain()[1]))
      .attr("y", y(getRange()[1]))
      .style("fill", "#9e9e9e")
      .style("font-style", "italic")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");

    hi.style("transform", function () {
      const width = hi.selectAll("text").node().getBBox().width;
      return `translate(-${windowWidth > 1440 ? width : width - 20}px, -5px)`;
    });

    const hd = svg
      .selectAll(".axisLabels")
      .append("g")
      .style("transform", function () {
        return "rotate(90deg)  translate(0px, -10px)";
      });
    hd.append("text")
      .text("← hogere dekking")
      .attr("x", x(getDomain()[0]))
      .attr("y", -width)
      .style("fill", "#9e9e9e")
      .style("font-style", "italic")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");

    const ld = svg.selectAll(".axisLabels").append("g");
    ld.append("text")
      .text("lagere dekking →")
      .attr("x", height)
      .attr("y", -width)
      .style("fill", "#9e9e9e")
      .style("font-style", "italic")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");

    ld.style("transform", function () {
      const width = ld.selectAll("text").node().getBBox().width;
      return "rotate(90deg)  translate(-" + width + "px, -10px)";
    });

    //Legend

    const legend = svg.append("g").attr("class", "legend");
    legend
      .attr("x", 0)
      .attr("y", 0)
      .style("transform", "translate(0px, -30px)");
    legend
      .append("circle")
      .attr("cx", x(getDomain()[1] - (windowWidth > 1440 ? 25 : 30)))
      .attr("cy", y(getRange()[1] + 5))
      .attr("r", 6)
      .style("fill", "#b5b3b3");
    legend
      .append("circle")
      .attr("cx", x(getDomain()[1] - 5))
      .attr("cy", y(getRange()[1] + 5))
      .attr("r", 6)
      .style("fill", "#124c53");
    legend
      .append("text")
      .attr("x", x(getDomain()[1] - (windowWidth > 1440 ? 20 : 25)))
      .attr("y", y(getRange()[1] + 5))
      .text(dataset[0].year)
      .style("font-size", "15px")
      .attr("alignment-baseline", "central")
      .style("dominant-baseline", "middle")
      .style("text-anchor", "middle")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");
    legend
      .append("text")
      .attr("x", x(getDomain()[1]))
      .attr("y", y(getRange()[1] + 5))
      .text(dataset[1].year)
      .attr("alignment-baseline", "central")
      .style("dominant-baseline", "middle")
      .style("text-anchor", "middle")
      .style("font-size", windowWidth > 1440 ? "medium" : "small");

    //Raster

    svg.append("g").attr("class", "raster");
    svg
      .selectAll(".raster")
      .append("g")
      .attr("class", "verticalGroup")
      .selectAll("line")
      .data(
        createAxisInterval(
          20,
          dataset[0].values.map((entry) => entry.xValue)
        ).filter((value) => value >= getDomain()[0])
      )
      .join("line")
      .attr("x1", (d) => x(d))
      .attr("x2", (d) => x(d))
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke", "#bababa")
      .attr("stroke-width", "1px");

    svg
      .selectAll(".raster")
      .append("g")
      .attr("class", "horizontalGroup")
      .selectAll("line")
      .data(
        createAxisInterval(
          50,
          dataset[0].values.map((entry) => entry.yValue)
        )
      )
      .join("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", (d) => y(d))
      .attr("y2", (d) => y(d))
      .attr("stroke", "#bababa")
      .attr("stroke-width", "1px");

    // 100 lines

    svg.append("g").attr("class", "centField");

    svg
      .select(".centField")
      .append("line")
      .attr("x1", x(100))
      .attr("x2", x(100))
      .attr("y1", 0)
      .attr("y2", height)
      .attr("stroke", "#bababa")
      .attr("stroke-width", "1.5px");

    svg
      .select(".centField")
      .append("line")
      .attr("x1", 0)
      .attr("x2", width)
      .attr("y1", y(100))
      .attr("y2", y(100))
      .attr("stroke", "#bababa")
      .attr("stroke-width", "1.5px");

    // 100 rect

    svg
      .select(".centField")
      .append("rect")
      .attr("x", x(getDomain()[0]))
      .attr("y", y(100))
      .attr("height", height - y(100))
      .attr("width", x(100))
      .attr("stroke", "#bababa")
      .style("opacity", "0.1");

    // Dots
    renderConnections(svg, x, y);
    renderDots(svg, x, y);

    // Help lines
    const helpLines = svg.append("g").attr("class", "helpLines");
    helpLines.append("line").attr("class", "horizontalLine");
    helpLines.append("line").attr("class", "verticalLine");
    const vertical = svg
      .selectAll(".helpLines")
      .select(".verticalLine")
      .attr("x1", x(getDomain()[0]))
      .attr("x2", x(getDomain()[0]))
      .attr("y1", y(0))
      .attr("y2", y(0))
      .attr("stroke", "red")
      .attr("stroke-dasharray", "10,10")
      .attr("stroke-width", "1.5px");

    const horizontal = svg
      .selectAll(".helpLines")
      .select(".horizontalLine")
      .attr("x1", x(getDomain()[0]))
      .attr("x2", x(getDomain()[0]))
      .attr("y1", y(0))
      .attr("y2", y(0))
      .attr("stroke", "red")
      .attr("stroke-dasharray", "10,10")
      .attr("stroke-width", "1.5px");

    const helpName = svg
      .selectAll(".helpLines")
      .append("text")
      .attr("x", x(getDomain()[0]))
      .attr("y", y(0))
      .style("fill", "red")
      .style("font-weight", "bold")
      .text(getHiglightedDot(svg) ? getHiglightedDot(svg).name : "")
      .style("font-size", "small");

    // Highlighted X and Y values

    const xGroup = svg.append("g").attr("class", "xGroup");
    const yGroup = svg.append("g").attr("class", "yGroup");

    const xVal = xGroup
      .append("text")
      .attr("class", "xVal")
      .attr("dx", x(getDomain()[0]))
      .attr("dy", y(0))
      .style("fill", "red")
      .text(getHiglightedDot(svg) ? getHiglightedDot(svg).xValue : "")
      .style("font-weight", "bold")
      .style("transform", function () {
        const height = this.getBBox().height;
        return `translate(0px, ${height}px)`;
      })
      .style("font-size", "small");

    const xBackground = xGroup
      .insert("rect", "text")
      .attr("x", x(getDomain()[0]))
      .attr("y", y(0))
      .attr("width", xVal.node().getBBox().width)
      .attr("height", xVal.node().getBBox().height)
      .style("transform", "translate(0px, 10px)")
      .style("fill", "white");

    const yVal = yGroup
      .append("text")
      .attr("class", "yVal")
      .attr("dx", x(getDomain()[0]))
      .attr("dy", y(0))
      .style("fill", "red")
      .text(getHiglightedDot(svg) ? getHiglightedDot(svg).yValue : "")
      .style("font-weight", "bold")
      .style("transform", function () {
        const width = this.getBBox().width;
        return `translate(-${width}px, 0px)`;
      })
      .style("font-size", "small");

    const yBackground = yGroup
      .insert("rect", "text")
      .attr("x", x(getDomain()[0]))
      .attr("y", y(0))
      .attr("width", yVal.node().getBBox().width)
      .attr("height", yVal.node().getBBox().height)
      .style(
        "transform",
        `translate(-${yVal.node().getBBox().width + 10}px, -${
          yVal.node().getBBox().height - 5
        }px)`
      )
      .style("fill", "white");

    selectedArea.subscribe(() =>
      colorSelectedValues(
        svg,
        x,
        y,
        vertical,
        horizontal,
        xVal,
        yVal,
        xBackground,
        yBackground,
        helpName
      )
    );
  });
</script>

<svelte:window bind:innerWidth={windowWidth} />

<div bind:clientHeight={cheight} bind:clientWidth={cwidth} class="container">
  <div bind:this={svgRef} class="chart" />
</div>

<style>
  .container {
    min-height: 85%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart {
    display: flex;
    flex-grow: 1;
    justify-content: right;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
    padding: 0rem 1rem;
  }
</style>
