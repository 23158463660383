<script>
  export let dataset;
  export let handleColor = () => {};
  export let geoData = [];
  import { selectedArea } from "../../stores/stores.js";
  import { geoPath } from "d3";
  import { draw } from "svelte/transition";
  import { quadInOut } from "svelte/easing";
  import { onMount } from "svelte";
  import * as d3 from "d3";

  let toolTip, htmlGroup, cwidth;

  const scale = 120000;
  const projection = d3.geoMercator().center([4.3, 50.838]).scale(scale);
  const path = geoPath(projection);
  const notSelected = geoData.filter(
    (value) => value.properties.name != $selectedArea
  );
  const selected = geoData
    .filter((value) => value.properties.name === $selectedArea)
    .pop();

  const isEnabled = (value) => {
    const enabledList = dataset.filter((entry) => {
      const result = geoData
        .map((item) => item.properties.name)
        .includes(entry.name);
      return result;
    });
    return enabledList.map((item) => item.name).includes(value);
  };

  const mouseOver = (event) => {
    toolTip.style.opacity = 1;
    const selector = event.target.getAttribute("value");
    if (isEnabled(selector))
      d3.selectAll(".group").selectAll("path").style("opacity", 0.6);
    d3.select(event.target).style("opacity", 1);
  };

  const mouseMove = (e, name) => {
    toolTip.innerHTML = name;
  };

  const mouseLeave = () => {
    toolTip.style.opacity = 0;
    d3.selectAll(".group").selectAll("path").style("opacity", 1);
  };

  const selectArea = (event) => {
    const selector = event.target.getAttribute("value");
    if (isEnabled(selector)) {
      selectedArea.set(selector);
      d3.select(event.target).style("opacity", 1).style("stroke", "#FF3300");
    }
  };
  const handleDraw = (selector) => {
    if (selector.getAttribute("value") === $selectedArea)
      return { duration: 700, delay: 0, easing: quadInOut };
  };

  const handleFill = (area) => {
    const result = dataset.filter((value) => value.name === area).pop();
    if (!result || result.value === 0) return "#ededed";
    return handleColor(result.value);
  };

  onMount(() => {
    toolTip.setAttribute(
      "x",
      cwidth > 1024 ? htmlGroup.getBBox().width / 5 : 10
    );
    toolTip.setAttribute("y", htmlGroup.getBBox().height / 5);
  });
</script>

<svelte:window bind:innerWidth={cwidth} />
<g class="group" bind:this={htmlGroup}>
  {#each notSelected as data}
    <path
      on:mouseover={mouseOver}
      on:mouseleave={mouseLeave}
      on:mousemove={(e) => mouseMove(e, data.properties.name)}
      on:focus={selectArea}
      on:click={selectArea}
      class={isEnabled(data.properties.name) ? "" : "disabled"}
      d={path(data)}
      value={data.properties.name}
      fill={handleFill(data.properties.name)}
      id={data.properties.name}
      title={data.properties.name}
    />
  {/each}
  {#if selected}<path
      on:mouseover={mouseOver}
      on:mouseleave={mouseLeave}
      on:mousemove={(e) => mouseMove(e, selected.properties.name)}
      on:focus={selectArea}
      on:click={selectArea}
      class="selected"
      in:draw={() => handleDraw(this)}
      d={path(selected)}
      value={selected.properties.name}
      fill={handleFill(selected.properties.name)}
    />{/if}
  <text bind:this={toolTip} class="toolTip" />
</g>

<style>
  .toolTip {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 100%;
    fill: black;
    border: solid;
    border-width: 2px;
    border-radius: 5px;
    padding: 5px;
  }
  path {
    stroke: white;
    stroke-width: 0.5px;
    cursor: pointer;
    transform: translate(-200px, 50px);
    -webkit-transform: translate(-200px, 50px);
    margin: 25px;
  }

  :focus {
    outline: none;
  }

  .selected {
    stroke-width: 2px;
    stroke: rgb(255, 54, 0);
  }

  .disabled {
    cursor: not-allowed;
  }

  @media (max-width: 1440px) {
    path {
      transform: scale(80%) translate(-100px, 50px);
      -webkit-transform: scale(80%) translate(-100px, 50px);
    }
  }

  @media (max-width: 1300px) {
    path {
      transform: scale(65%) translate(-150px, 45px);
      -webkit-transform: scale(65%) translate(-150px, 45px);
    }
  }
  @media (max-width: 1024px) {
    path {
      transform: scale(70%) translate(-300px, 50px);
      -webkit-transform: scale(70%) translate(-300px, 50px);
    }
    .toolTip {
      font-size: small;
    }
  }

  @media (max-height: 880px) {
    path {
      transform: scale(80%) translate(-150px, 45px);
      -webkit-transform: scale(80%) translate(-150px, 45px);
    }
  }

  @media (max-height: 700px) {
    path {
      transform: scale(60%) translate(-150px, 45px);
      -webkit-transform: scale(60%) translate(-150px, 45px);
    }
  }

  @media (max-width: 770px) {
    path {
      transform: scale(55%) translate(-300px);
      -webkit-transform: scale(55%) translate(-300px);
    }
  }
</style>
