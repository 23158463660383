import * as d3 from "d3";

export const alignTextToRight = (svg, identifier, item) => {
  if (svg) {
    const maxWidth = d3.max(
      svg
        .selectAll(identifier)
        .nodes()
        .map((value) => value.getBBox().width)
    );
    const result = maxWidth - item.getBBox().width;
    return result;
  }
};
