<script>
  export let data;
  import { onMount } from "svelte";
  import * as d3 from "d3";
  import { selectedArea } from "../../stores/stores.js";
  import { alignTextToRight } from "../../utils/alignTextToRight.js";
  import { centerTextToGraph } from "../../utils/centerTextToGraph.js";
  import { addAdfix } from "../../utils/addAdfix.js";
  import { staticPalette } from "../../utils/staticPalette.js";
  let el;
  let cwidth, cheight, windowWidth;
  let svg;
  const color = staticPalette();

  const colorSelectedBar = () => {
    svg.selectAll(".rect-left").attr("fill", (d) => {
      return d.name === $selectedArea ? color[0].highlight : color[0].color;
    });
    svg.selectAll(".label").style("font-weight", (d) => {
      return d.name === $selectedArea ? "bold" : "normal";
    });
    svg.selectAll(".value").style("font-weight", (d) => {
      return d.name === $selectedArea ? "bold" : "normal";
    });
  };

  const handleClick = (data) => {
    selectedArea.set(data.name);
  };

  const drawGraph = (data, x, y, width) => {
    const insertedData = data.values.sort((a, b) => {
      if (/[0-9]+/.test(a.name) && /[0-9]+/.test(b.name)) {
        return b.name - a.name;
      } else return b.value - a.value;
    });
    svg.selectAll("myRect").data(insertedData).join("g").attr("class", "bar");

    svg
      .selectAll(".bar")
      .data(insertedData)
      .append("rect")
      .attr("class", "rect-left")
      .attr("x", x(0))
      .attr("y", (d) => y(d.name))
      .style("cursor", "pointer")
      .on("click", (e, d) => handleClick(d));

    svg
      .selectAll(".rect-left")
      .attr("height", y.bandwidth())
      .transition()
      .duration(500)
      .attr("width", (d) => x(d.value));

    svg
      .selectAll(".bar")
      .data(insertedData)
      .append("rect")
      .attr("class", "rect-right")
      .attr("height", y.bandwidth())
      .attr("y", (d) => y(d.name))
      .attr("fill", color[1].color)
      .transition()
      .duration(500)
      .attr("x", (d) => x(d.value))
      .attr(
        "width",
        (d) =>
          x(Math.max(...insertedData.map((value) => value.value))) - x(d.value)
      );

    svg
      .selectAll(".bar")
      .append("g")
      .attr("class", "valueGroup")
      .style(
        "transform",
        windowWidth > 1440 ? "translate(20px, 0px)" : "translate(5px, 0px)"
      )
      .data(insertedData)
      .append("text")
      .attr("class", "value")
      .attr("x", width)
      .attr("y", (d) => y(d.name) + centerTextToGraph(svg, ".rect-left"))
      .style("font-weight", "regular")
      .style("font-family", "Lato")
      .style("fill", "#333")
      .style("font-size", windowWidth > 1440 ? "medium" : "small")
      .style("alignment-baseline", "central")
      .style("dominant-baseline", "hanging")
      .style("cursor", "pointer")
      .on("click", (e, d) => handleClick(d))
      .text((d) =>
        addAdfix(data.objectInfo.adfix, parseFloat(d.value).toLocaleString())
      );

    svg
      .selectAll(".bar")
      .append("g")
      .attr("class", "textGroup")
      .style("transform", (d) => {
        if (windowWidth > 1440) {
          return /[0-9]+/.test(d.name)
            ? "translate(-60px, 0px)"
            : "translate(-190px, 0px)";
        } else {
          return /[0-9]+/.test(d.name)
            ? "translate(-40px, 0px)"
            : "translate(-150px, 0px)";
        }
      })
      .data(insertedData)
      .append("text")
      .attr("x", x(0))
      .attr("y", (d) => y(d.name) + centerTextToGraph(svg, ".rect-left"))
      .attr("class", "label")
      .style("font-weight", "regular")
      .style("font-family", "Lato")
      .style("fill", "#333")
      .style("font-size", windowWidth > 1440 ? "medium" : "small")
      .style("alignment-baseline", "central")
      .style("dominant-baseline", "hanging")
      .style("cursor", "pointer")
      .on("click", (e, d) => handleClick(d))
      .text((d) => d.name);

    svg.selectAll(".label").attr("x", function () {
      return alignTextToRight(svg, ".label", this);
    });
  };

  onMount(() => {
    const margin = {
      top: 10,
      right: 80,
      bottom: 20,
      left: windowWidth > 1440 ? 200 : 160,
    };
    const width = cwidth - margin.left - margin.right;
    const height = cheight;

    svg = d3
      .select(el)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Add X axis
    const x = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(
          data.values
            .sort((a, b) => {
              if (/[0-9]+/.test(a.name) && /[0-9]+/.test(b.name)) {
                return b.name - a.name;
              } else return b.value - a.value;
            })
            .map((value) => value.value)
        ),
      ])
      .range([0, width]);

    // Y axis
    const y = d3
      .scaleBand()
      .range([0, height])
      .domain(
        data.values
          .sort((a, b) => {
            if (/[0-9]+/.test(a.name) && /[0-9]+/.test(b.name)) {
              return b.name - a.name;
            } else return b.value - a.value;
          })
          .map((d) => d.name)
      )
      .padding(0.2);

    svg
      .append("g")
      .attr("transform", `translate(0, ${height})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(5)
          .tickFormat((d) => addAdfix(data.objectInfo.adfix, d))
      );
    svg.select(".domain").attr("stroke", "transparant");

    drawGraph(data, x, y, width);

    selectedArea.subscribe(() => colorSelectedBar());
  });
</script>

<svelte:window bind:innerWidth={windowWidth} />

<div bind:clientWidth={cwidth} bind:clientHeight={cheight} class="container">
  <div bind:this={el} class="chart" />
</div>

<style>
  .container {
    min-height: 85%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart {
    display: flex;
    flex-grow: 1;
    justify-content: right;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
    padding: 0rem 1rem;
  }

  @media (min-width: 1440px) {
    .chart {
      height: 1000px;
    }
  }

  @media (min-width: 1024px) {
    .chart {
      height: 300px;
    }
  }

  @media (min-width: 770px) {
    .chart {
      height: 200px;
    }
  }
</style>
