import BarGraph from "../componenten/charts/BarGraph.svelte";
import ClevelandGraph from "../componenten/charts/ClevelandGraph.svelte";
import BeeswarmGraph from "../componenten/charts/BeeswarmGraph.svelte";
import StackedGraph from "../componenten/charts/StackedGraph.svelte";
import Scatterplot from "../componenten/charts/Scatterplot.svelte";
export const getGraphComponent = (selector) => {
  let graph;
  switch (selector) {
    case "Staafdiagram":
      graph = BarGraph;
      break;
    case "Lijngrafiek":
      break;
    case "Gestapeld staafdiagram":
      graph = StackedGraph;
      break;
    case "Dumbbell":
      graph = ClevelandGraph;
      break;
    case "Spreidingsdiagram":
      graph = Scatterplot;
      break;
    case "Beeswarm":
      graph = BeeswarmGraph;
      break;
    default:
      graph = BarGraph;
      console.log("No graph found; sampeling bar graph");
      break;
  }
  return graph;
};
