<script>
  import Marks from "./Marks.svelte";
  import { treshPalette } from "../../utils/treshPalette.js";

  export let data;
  export let geoData;
  export let useDefaultColor;
  export let selectedColumn;

  const formatDataSet = () => {
    if (useDefaultColor) {
      return data.reduce(
        (acc, current) => {
          acc = {
            objectInfo: { ...acc.objectInfo, ...current.objectInfo },
            values: [...acc.values, ...current.values].reduce(
              (acc, current) => {
                if (acc.map((item) => item.name).includes(current.name)) {
                  const index = acc
                    .map((item) => item.name)
                    .indexOf(current.name);
                  acc[index] = current;
                } else acc.push(current);
                return acc;
              },
              []
            ),
          };
          return acc;
        },
        { objectInfo: {}, values: [] }
      );
    }
    return Array.isArray(data)
      ? data.find((element) => element.objectInfo.kolomnaam === selectedColumn)
      : data;
  };
  const dataset = formatDataSet();
  const handleColor = treshPalette(
    Math.min(...dataset.values.map((item) => item.value)),
    Math.max(...dataset.values.map((item) => item.value))
  );
</script>

<div class="container">
  <svg>
    <Marks dataset={dataset.values} {geoData} {handleColor} />
  </svg>
</div>

<style>
  .container {
    height: 100%;
    min-height: 25vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  svg {
    width: 100%;
    height: 100%;
  }
</style>
