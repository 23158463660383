import * as geoTownshipData from "../mapData/townshipData.geojson";
import * as geoDistrictData from "../mapData/districtData.geojson";
export const getMap = (level) => {
  switch (level) {
    case "Gemeente":
      return geoTownshipData.features;
    case "Wijk":
      return geoDistrictData.features;
    default:
      return geoTownshipData.features;
  }
};
