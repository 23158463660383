<script>
  import { onMount } from "svelte";
  import { createAxisInterval } from "../../utils/createAxisInterval.js";
  import { selectedArea } from "../../stores/stores.js";
  import { addAdfix } from "../../utils/addAdfix.js";
  import * as d3 from "d3";
  export let data;

  let cheight,
    cwidth,
    graphRef,
    selected = [],
    notSelected = [];

  const formatDataset = () => {
    const formated = Array.isArray(data)
      ? data.map((entry) => {
          return {
            column: entry.objectInfo.kolomnaam,
            adfix: entry.objectInfo.adfix,
            value: entry.values,
          };
        })
      : [
          {
            column: data.objectInfo.langeNaam,
            value: data.values,
            adfix: data.objectInfo.adfix,
          },
        ];

    const areas = [
      ...new Set(
        formated.map((item) => item.value.map((entry) => entry.name)).flat()
      ),
    ];
    const result = areas.reduce((acc, current) => {
      formated.forEach((element) => {
        const found = element.value.find((item) => item.name === current);
        if (!found) {
          element.value = [...element.value, { name: current, value: 0 }];
        }
      });
      acc = formated;
      return acc;
    }, {});
    return result;
  };

  const dataset = formatDataset();

  const maxRange = dataset.reduce(
    (acc, current) =>
      acc >= d3.max(current.value.map((item) => item.value))
        ? acc
        : d3.max(current.value.map((item) => item.value)),
    d3.max(dataset[0].value)
  );

  const dotData = dataset
    .reduce((acc, current) => {
      const newValues = current.value.map((entry) => {
        return {
          column: current.column,
          value: entry.value,
          name: entry.name,
          adfix: current.adfix,
        };
      });
      acc.push(newValues);
      return acc;
    }, [])
    .flat();

  const renderConnection = (connection, startIndex, x, y) => {
    if (startIndex + 1 < selected.length) {
      connection
        .selectAll(".connection" + startIndex)
        .transition()
        .duration(1000)
        .attr("x1", x(selected[startIndex].column) + x.bandwidth() / 2)
        .attr("x2", x(selected[startIndex + 1].column) + x.bandwidth() / 2)
        .attr("y1", y(selected[startIndex].value))
        .attr("y2", y(selected[startIndex + 1].value))
        .style("stroke", "#ff3600");
      return renderConnection(connection, startIndex + 1, x, y);
    }
  };

  const colorSelectedArea = (
    x,
    y,
    selectedDot,
    dots,
    lines,
    highlightedValues,
    connection,
    width
  ) => {
    dots
      .data(notSelected)
      .attr("cx", function (d) {
        return x(d.column) + x.bandwidth() / 2;
      })
      .attr("cy", function (d) {
        return y(d.value);
      })
      .attr("r", 5);

    selectedDot
      .data(selected)
      .transition()
      .duration(1000)
      .attr("cx", function (d) {
        return x(d.column) + x.bandwidth() / 2;
      })
      .attr("cy", function (d) {
        return y(d.value);
      })
      .attr("r", 5);

    lines
      .data(selected)
      .transition()
      .duration(1000)
      .attr("x1", (d) => x(d.column) + x.bandwidth() / 2)
      .attr("x2", function (d, i) {
        if (i - 1 >= 0) {
          const absolute = Math.abs(selected[i - 1].value - selected[i].value);
          return absolute <= 10
            ? width -
                d3.selectAll(".highlighted").selectAll("text").node().getBBox()
                  .width -
                5
            : width;
        } else return width;
      })
      .attr("y1", (d) => {
        return y(d.value);
      })
      .attr("y2", (d) => y(d.value));

    highlightedValues
      .data(selected)
      .transition()
      .duration(1000)
      .attr("y", (d) => y(d.value))
      .attr("x", function (d, i) {
        if (i - 1 >= 0) {
          const absolute = Math.abs(selected[i - 1].value - selected[i].value);
          return absolute <= 10
            ? width - d3.select(this).node().getBBox().width - 5
            : width;
        } else return width;
      })
      .text((d) => {
        return d.adfix ? addAdfix(d.adfix, d.value) : d.value;
      })
      .style("opacity", 1);

    if (selected.length > 1) {
      renderConnection(connection, 0, x, y);
    }
  };

  const refreshData = (value) => {
    notSelected = dotData.filter((entry) => entry.name != value);
    selected = dotData.filter((entry) => entry.name === value);
  };

  const handleChange = (
    value,
    x,
    y,
    selectedDot,
    dots,
    lines,
    highlightedValues,
    connection,
    width
  ) => {
    refreshData(value);

    colorSelectedArea(
      x,
      y,
      selectedDot,
      dots,
      lines,
      highlightedValues,
      connection,
      width
    );
  };

  onMount(() => {
    refreshData($selectedArea);
    // set the dimensions and margins of the graph
    const margin = { top: 10, right: 70, bottom: 30, left: 40 };
    const width = cwidth - margin.left - margin.right;
    const height = cheight - margin.top - margin.bottom;

    // append the svg object to the body of the page
    const svg = d3
      .select(graphRef)
      .append("svg")
      .attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Read the data and compute summary statistics for each specie
    // Build and Show the Y scale
    const y = d3
      .scaleLinear()
      .domain([0, maxRange + 10]) // Note that here the Y scale is set manually
      .range([height, 0]);
    svg.append("g").call(d3.axisLeft(y));

    // Build and Show the X scale. It is a band scale like for a boxplot: each group has an dedicated RANGE on the axis. This range has a length of x.bandwidth
    const x = d3
      .scaleBand()
      .range([0, width])
      .domain(dataset.map((item) => item.column))
      .padding(0.05); // This is important: it is the space between 2 groups. 0 means no padding. 1 is the maximum.
    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x));

    //Raster

    const raster = svg.append("g").attr("class", "raster");
    raster
      .selectAll("line")
      .data(
        createAxisInterval(
          20,
          dataset.map((item) => item.value.map((item) => item.value))
        )
      )
      .join("line")
      .attr("x1", 0)
      .attr("y1", (d) => y(d))
      .attr("x2", width)
      .attr("y2", (d) => y(d))
      .style("stroke", "#eaeaea");

    // Features of the histogram
    const histogram = d3
      .bin()
      .domain(y.domain())
      .thresholds(y.ticks(20)) // Important: how many bins approx are going to be made? It is the 'resolution' of the violin plot
      .value((d) => d);
    const histoData = dataset.map((item) => {
      const values = item.value.map((entry) => entry.value);
      return {
        ...item,
        value: histogram(values),
      };
    });

    // What is the biggest number of value in a bin? We need it cause this value will have a width of 100% of the bandwidth.
    let maxNum = 0;
    for (const i in histoData) {
      const allBins = histoData[i].value;
      const lengths = allBins.map(function (a) {
        return a.length;
      });
      const longuest = d3.max(lengths);
      if (longuest > maxNum) {
        maxNum = longuest;
      }
    }

    // The maximum width of a violin must be x.bandwidth = the width dedicated to a group
    const xNum = d3
      .scaleLinear()
      .range([0, x.bandwidth()])
      .domain([-maxNum, maxNum]);

    // Add the shape to this svg!
    svg
      .selectAll("myViolin")
      .data(histoData)
      .enter() // So now we are working group per group
      .append("g")
      .attr("transform", function (d) {
        return "translate(" + x(d.column) + " , 0)";
      }) // Translation on the right to be at the group position
      .append("path")
      .datum(function (d) {
        return d.value;
      }) // So now we are working bin per bin
      .style("stroke", "none")
      .style("fill", "#e1f0f3")
      .attr(
        "d",
        d3
          .area()
          .x0(function (d) {
            return xNum(-d.length);
          })
          .x1(function (d) {
            return xNum(d.length);
          })
          .y(function (d) {
            return y(d.x0);
          })
          .curve(d3.curveCatmullRom) // This makes the line smoother to give the violin appearance. Try d3.curveStep to see the difference
      );

    svg.append("g").attr("class", "leftDots");

    const dots = svg
      .selectAll(".leftDots")
      .selectAll("circle")
      .data(notSelected)
      .join("circle")
      .attr("cx", function (d) {
        return x(d.column) + x.bandwidth() / 2;
      })
      .attr("cy", y(0))
      .attr("r", 5)
      .style("fill", "#6dbcbd")
      .attr("fill-opacity", "20%")
      .attr("stroke", "#6dbcbd");

    svg.append("g").attr("class", "highlighted");

    const selectedDot = svg
      .selectAll(".highlighted")
      .selectAll("circle")
      .data(
        selected.length > 0
          ? selected
          : [...new Set(dataset.map((item) => item.column))]
      )
      .join("circle")
      .attr("cx", function (d) {
        return (selected.length > 0 ? x(d.column) : x(d)) + x.bandwidth() / 2;
      })
      .attr("cy", y(0))
      .attr("r", selected.length > 0 ? 5 : 0)
      .style("fill", "#ff5e33")
      .attr("stroke", "white");

    selectedDot
      .data(selected)
      .transition()
      .duration(1000)
      .attr("cx", function (d) {
        return x(d.column) + x.bandwidth() / 2;
      })
      .attr("cy", function (d) {
        return y(d.value);
      })
      .attr("r", 5);

    const lines = svg
      .selectAll(".highlighted")
      .selectAll("line")
      .data(
        selected.length > 0
          ? selected
          : [...new Set(dataset.map((item) => item.column))]
      )
      .join("line")
      .attr(
        "x1",
        (d) => (selected.length > 0 ? x(d.column) : x(d)) + x.bandwidth() / 2
      )
      .attr("x2", (d) =>
        selected.length > 0 ? width : x(d) + x.bandwidth() / 2
      )
      .attr("y1", y(0))
      .attr("y2", y(0))
      .style("stroke", "red")
      .style("stroke-dasharray", "5,5");

    const highlightedValues = svg
      .selectAll(".highlighted")
      .selectAll("text")
      .data(
        selected.length > 0
          ? selected
          : [...new Set(dataset.map((item) => item.column))]
      )
      .join("text")
      .attr("x", width)
      .attr("y", (d) => y(0))
      .style("fill", "red");

    const connection = svg.append("g").attr("class", "connection");
    if (selected.length > 1 || selected.length === 0) {
      connection
        .selectAll("line")
        .data(
          selected.length > 0
            ? selected
            : [...new Set(dataset.map((item) => item.column))]
        )
        .join("line")
        .attr("class", (d, i) => "connection" + i)
        .attr(
          "x1",
          (d) => (selected.length > 0 ? x(d.column) : x(d)) + x.bandwidth() / 2
        )
        .attr(
          "x2",
          (d) => (selected.length > 0 ? x(d.column) : x(d)) + x.bandwidth() / 2
        )
        .attr("y1", y(0))
        .attr("y2", y(0));
    }
    selectedArea.subscribe((value) =>
      handleChange(
        value,
        x,
        y,
        selectedDot,
        dots,
        lines,
        highlightedValues,
        connection,
        width
      )
    );
  });
</script>

<div bind:clientWidth={cwidth} bind:clientHeight={cheight} class="container">
  <div bind:this={graphRef} class="chart" />
</div>

<style>
  .container {
    min-height: 85%;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chart {
    display: flex;
    flex-grow: 1;
    justify-content: right;
    align-items: center;
    min-height: 100%;
    min-width: 100%;
    padding: 0rem 1rem;
  }

  @media (min-width: 1440px) {
    .chart {
      height: 1000px;
    }
  }

  @media (min-width: 1024px) {
    .chart {
      height: 300px;
    }
  }

  @media (min-width: 770px) {
    .chart {
      height: 200px;
    }
  }
</style>
