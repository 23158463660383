export const addAdfix = (adfix, string) => {
  switch (adfix) {
    case "+":
    case "-":
    case "€":
      return adfix + string;
    case "%":
      return string + adfix;
    case "+":
    default:
      return string;
  }
};
