<script>
  export let handleClick = () => {};
  export let label = "";
</script>

<div class="container" on:click={handleClick}>
  <div class="label">{label}</div>
  <div class="value"><slot>///</slot></div>
</div>

<style>
  .container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0px;
  }
  :hover {
    text-decoration: underline;
    cursor: pointer;
  }

  .label {
    font-size: medium;
    word-break: keep-all;
  }
  .value {
    font-size: medium;
    font-weight: bold;
  }
  @media (max-width: 1440px) {
    .label {
      font-size: medium;
    }

    .value {
      font-size: medium;
    }
  }

  @media (max-height: 700px) {
    .value {
      font-size: small;
    }
    .label {
      font-size: small;
    }
  }

  @media (max-width: 770px) {
    .value {
      font-size: small;
    }
    .label {
      font-size: small;
    }
  }
</style>
