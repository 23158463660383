import * as dataSet from "../mockdata/csvjsonv3.json";
import { omitKeys } from "../utils/omitKeys.js";

export const removeEmptyValues = (variable, removeKeys = omitKeys) => {
  if (!variable || variable === null) {
    throw new Error("No value found");
  }
  if (
    typeof variable === "string" ||
    typeof variable === "number" ||
    typeof variable === "boolean"
  ) {
    throw new Error("Invalid value: " + typeof variable);
  }
  const emptyValues = [
    ...new Set(
      Object.entries(variable).filter(
        (value) =>
          value[1] === "" || (!value[1] && value[1] != 0) || value[1] < 0
      )
    ),
  ].map((value) => value[0]);

  const filledValues = removeKeys(variable, emptyValues);

  if (
    filledValues &&
    Object.keys(filledValues).length === 0 &&
    Object.getPrototypeOf(filledValues) === Object.prototype
  ) {
    throw new Error("Empty object");
  }
  if (!filledValues.id || filledValues.id === "") {
    throw new Error("Error: missing required id property");
  }
  if (!filledValues.categorie || filledValues.categorie === "") {
    throw new Error("Error: missing required category property");
  }
  return filledValues;
};

export const getDataSet = (data) => {
  try {
    if (data.length === 0) {
      throw new Error("datalist is empty (contains no values)");
    }
    const set = data.map((variable) => removeEmptyValues(variable));
    const categories = [...new Set(set.map((value) => value.categorie))];
    let sortedByCategory = [];
    categories.forEach((categorie) => {
      let newObject = {};
      newObject[categorie] = groupById(
        set.filter((obj) => obj.categorie === categorie)
      );
      sortedByCategory.push(newObject);
    });
    return sortedByCategory;
  } catch (err) {
    throw new Error("error when parsing data from the csv -> " + err.message);
  }
};

export const formatValue = (value) => {
  if ((!value && value != 0) || value === null) {
    throw new Error("no value was given");
  }
  if (Array.isArray(value)) {
    throw new Error("formatting array objects is not supported");
  }
  if (typeof value === "string") {
    if (/^(\d+|(\.\d+))(\.\d+)?%?$/.test(value)) {
      return parseFloat(value);
    } else return value;
  } else if (typeof value === "object") {
    const objectArray = Object.entries(value).map((item) => {
      return { name: item[0], value: formatValue(item[1]) };
    });
    const newObject = objectArray.reduce(
      (obj, item) => Object.assign(obj, { [item.name]: item.value }),
      {}
    );
    return newObject;
  } else return value;
};

export const groupById = (data) => {
  const arrayGrouped = data.reduce((r, a) => {
    if (!a.id) {
      throw new Error("can't group objects without an id value");
    }
    r[a.id] = r[a.id] || [];
    r[a.id].push(a);
    return r;
  }, Object.create(null));
  const objectGrouped = Object.values(arrayGrouped).map((value) =>
    value.length <= 1 ? value.pop() : value
  );
  return objectGrouped;
};

export const splitDataValues = (data) => {
  const {
    id,
    categorie,
    niveau,
    adfix,
    omschrijving,
    grafiektype,
    kolomnummer,
    kolomnaam,
  } = data;

  if (!id || id === "" || id === null) {
    throw new Error("required id value is empty!");
  }
  if (!categorie || categorie === "" || categorie === null) {
    throw new Error("required categorie value is empty!");
  }
  if (!niveau || niveau === "" || niveau === null) {
    throw new Error("required niveau value is empty!");
  }

  if (!grafiektype || grafiektype === "" || grafiektype === null) {
    throw new Error("required grafiektype value is empty!");
  }

  if (
    !data["lange naam"] ||
    (data["lange naam"] === "") | (data["lange naam"] === null)
  ) {
    throw new Error("required lange naam value is empty!");
  }

  if (
    !data["korte naam"] ||
    (data["korte naam"] === "") | (data["korte naam"] === null)
  ) {
    throw new Error("required korte naam value is empty!");
  }

  const objectInfo = {
    id,
    categorie,
    niveau,
    adfix,
    langeNaam: data["lange naam"],
    korteNaam: data["korte naam"],
    omschrijving,
    grafiektype,
    aantalKolommen: data["aantal kolommen"],
    kolomnummer,
    kolomnaam,
  };
  try {
    const removeKeys = Object.entries(data)
      .filter((value) => Object.values(objectInfo).includes(value[1]))
      .map((value) => value[0]);
    const objectValues = omitKeys(data, removeKeys);
    const arrayValues = Object.entries(objectValues)
      .map((value) => {
        return { name: value[0], value: formatValue(value[1]) };
      })
      .sort((a, b) => b.value - a.value);
    const result = { objectInfo, values: arrayValues };
    return result;
  } catch (error) {
    throw new Error(
      "An error has occured trying to format the data list: " + error.message
    );
  }
};

export const getAllVariables = () => {
  const set = getDataSet(dataSet.default);
  const formatedObjectArray = set.map((entry) =>
    Object.entries(entry)
      .map((value) => {
        const shortNames = [
          ...new Set(value[1].flat(2).map((value) => value["korte naam"])),
        ];

        const ids = [...new Set(value[1].flat(2).map((value) => value.id))];

        return { category: value[0], shortNames, ids };
      })
      .pop()
  );
  return formatedObjectArray;
};

export const getVariableLevel = (variable) => {
  try {
    if (!variable || variable === null || variable === "") {
      throw new Error("no variable was given!");
    }
    const set = getDataSet(dataSet.default);
    const filteredSet = set.map((object) => Object.values(object)).flat(2);
    const result = searchVariableById(filteredSet, variable);
    return Array.isArray(result) ? result[0].niveau : result.niveau;
  } catch (error) {
    throw new Error(
      "An error was found trying to assert the map/graph level: " +
        error.message
    );
  }
};

export const formatAreaInfoObject = (values, selectedArea) => {
  if (!values || values === null) {
    throw new Error("data list is not defined!");
  }
  if (values.length === 0) {
    throw new Error("data list is empty!");
  }
  const result = values.map((item) => {
    if (Array.isArray(item)) {
      return formatAreaInfoObject(item, selectedArea);
    }
    if (!item["id"] || item["id"] === "" || item["id"] === null) {
      throw new Error("missing required id value!");
    }
    if (
      !item["korte naam"] ||
      item["korte naam"] === "" ||
      item["korte naam"] === null
    ) {
      throw new Error("missing required korte naam value!");
    }
    const newObject = {
      id: item.id,
      shortName: item["korte naam"],
      longName: item["lange naam"],
      adfix: item.adfix ? item.adfix : "",
      value: item[selectedArea] ? item[selectedArea] : "",
      columnName: item["kolomnaam"] ? item["kolomnaam"] : item["korte naam"],
      columnNumber: item["kolomnummer"],
      level: item["niveau"],
      cat: item["categorie"],
      graph: item["grafiektype"],
    };
    return newObject;
  });

  return result;
};

export const getDataByArea = async (selectedArea, insertedData) => {
  try {
    const set = getDataSet(insertedData);
    const res = set.map((value) => {
      const newObject = Object.entries(value).map((variable) => {
        const values = formatAreaInfoObject(variable[1], selectedArea);
        const groupedObject = { category: variable[0], values: values };
        return groupedObject;
      });
      const finalObject = newObject.pop();
      return finalObject;
    });
    return res;
  } catch (error) {
    throw new Error(
      "An error has occured trying to load the data list for the collapsable window: " +
        error.message
    );
  }
};

export const searchVariableById = (list, variable, prevList = []) => {
  if (!list) {
    throw new Error("no list was given to search!");
  }
  if (!variable) {
    throw new Error("can't search the list without an id!");
  }
  if (typeof variable != "number") {
    throw new Error("id can only be a number value!");
  }
  if (list.length <= 0) {
    if (prevList.length > 0) {
      return searchVariableById(prevList, variable);
    } else throw new Error("no entry for that variable id was found!");
  } else {
    const item = list.shift();
    if (Array.isArray(item)) {
      return searchVariableById(item, variable, list);
    }
    if (item.id === variable) {
      if (prevList.length === 0) {
        const lastCheck = [
          ...new Set(
            list.map((item) =>
              Array.isArray(item)
                ? item.flat().map((entry) => entry.id)
                : item.id
            )
          ),
        ];
        if (lastCheck.length === 1) {
          return [item, ...list];
        }

        return item;
      } else {
        return [item, ...list];
      }
    } else {
      return searchVariableById(list, variable, prevList);
    }
  }
};

export const getDataByVariable = async (selectedVar, insertedData) => {
  try {
    if (!selectedVar || selectedVar === "" || selectedVar === null) {
      throw new Error("a default selected variable (id) is required!");
    }
    const set = getDataSet(insertedData);
    const filteredSet = set.map((object) => Object.values(object)).flat(2);
    const data = searchVariableById(filteredSet, selectedVar);
    return Array.isArray(data)
      ? data.map((element) => splitDataValues(element))
      : splitDataValues(data);
  } catch (error) {
    throw new Error(
      "An error has occured trying to load the correct data for the graph: " +
        error.message
    );
  }
};

export const isValidArea = (area) => {
  if (!area || area === "" || area === null) {
    return Error("area value is empty");
  }
  const set = getDataSet(dataSet.default);
  const allAreas = set
    .map((item) => Object.values(item))
    .flat(3)
    .map((value) => splitDataValues(value).values.map((entry) => entry.name));
  const validAreas = [...new Set(allAreas.flat())].filter((value) =>
    value.match(/^([^0-9]*)$/)
  );
  return validAreas.includes(area);
};

export const isValidId = (id) => {
  if (!id || id === "" || id === null) {
    return Error("area value is empty");
  }
  const set = getDataSet(dataSet.default);
  const allIds = set
    .map((item) => Object.values(item))
    .flat(3)
    .map((value) => splitDataValues(value).objectInfo.id);
  const validIDS = [...new Set(allIds)];
  return validIDS.includes(id);
};
